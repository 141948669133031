import styled, { css } from 'styled-components';

interface ButtonProps {
  background?: string | undefined;
  backgroundHover?: string | undefined;
  borderRadius?: string | undefined;
  margin?: string | undefined;
  padding?: string | undefined;
  shadow?: string | undefined;
  textColor?: string | undefined;
  hoverTextColor?: string | undefined;
  variant?: 'primary' | 'secondary' | 'outline' | 'disabled' | 'danger';
  minWidth?: string;
  width?: string;
  height?: string;
  border?: string;
  textAlign?: string;
}

export const Button = styled.button<ButtonProps>`
  border: ${p => p.border ?? 'none'};
  text-align: ${p => p.textAlign ?? 'center'};
  cursor: pointer;
  min-width: ${p => p.minWidth ?? '100px'}; 
  color: black;
  font-family: 'D-DIN';
  font-weight: bold;
  height: ${p => p.height ?? '48px'};
  width: ${p => p.width};
  box-shadow: ${({ shadow }) => shadow ?? '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)'};
  background: ${({ background }) => background};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({ textColor }) => textColor ?? 'black'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '8px'};
  transition: ease-in-out 0.2s;
  opacity: 1;
  &:active {
    opacity: 0.4;
  }
  &:hover {
    opacity: 0.8;
    background: ${({ backgroundHover }) => backgroundHover};
    color: ${({ hoverTextColor }) => hoverTextColor};
  }
  ${props =>
    props.variant === 'primary' &&
    css`
      background: #FFC535;
      position: relative;
      background-size: 400%;
      background-position: 0% 0%;
      border-radius: 5px ;
      border: none;
      &:hover {
        background-size: 100%;
        background-position: 0% 0%;
        animation-duration: 2s;
        animation-iteration-count: 2;
      }
    `};
  ${props =>
    props.variant === 'secondary' &&
    css`
      background: #7b7b7b;
      color: white;
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'outline' &&
    css`
      background: transparent;
      border: 2px solid #FFC535;
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'danger' &&
    css`
      background: #e56c75;
      color: white;
      &:active {
        opacity: 0.7;
      }
    `};
  ${props =>
    props.variant === 'disabled' &&
    css`
      background: #c4c4c4;
      color: white;
      cursor: not-allowed !important;
    `};
    &:active {
        opacity: 1;
    }
`;
