import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "components/Layouts/styles";

interface BottomSheetProps {
  children?: React.ReactNode;
  borderradius?: string;
  height?: string;
  padding?: string;
  zindex?: string;
  minheight?: string;
  shadow?: string;
}

const BottomSheet = ({
  children,
  borderradius,
  height,
  padding,
  zindex,
  minheight,
  shadow,
}: BottomSheetProps) => {
  return (
    <Container>
      <BottomModal
        shadow={shadow}
        animate={{ y: [0, -5, 0] }}
        transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 1 }}
        borderradius={borderradius}
        height={height}
        padding={padding}
        zindex={zindex}
        minheight={minheight}
      >
        <Content padding={padding}>{children}</Content>
      </BottomModal>
    </Container>
  );
};

export const Container = styled.div`
  background: #000000a8;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
`;

export const BottomModal = styled(motion.div)<BottomSheetProps>`
  position: fixed;
  height: ${(props) => props.height ?? "204px"};
  min-height: ${(props) => props.minheight ?? ""};
  z-index: ${(props) => props.zindex ?? "2"};
  width: 100%;
  background: white;
  border: 1px solid #c4c4c4;
  border-width: 1px 0 0 0;
  bottom: 0;
  border-radius: ${(props) => props.borderradius ?? "20px 20px 0 0"};
  box-shadow: ${(props) => props.shadow ?? ""};

  @media ${device.laptop} {
    max-width: 360px;
  }
  @media ${device.laptopL} {
    max-width: 360px;
  }

  @media ${device.desktop} {
    max-width: 360px;
  }
`;

export const Content = styled.div<BottomSheetProps>`
  justify-content: center;
  text-align: center;
  padding: ${(props) => props.padding ?? "20px"};
`;

export default BottomSheet;
