import React, { useEffect } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Grid, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import InputField from "components/Form/InputField";
import { Button } from "components/styled/button.styled";
import { useNavigate } from "react-router-dom";
import useRedux from "redux/useRedux";
import moment from "moment";
import { postClosingPos, getAmountCash, AmountRespond } from "redux/closingPos";
import { AuthLogout } from "redux/auth";
import { formatCurrency, notify, removeAllState } from "helper/general";
import ModalSucces from "../../components/Modal/modalSuccess";

const ProfilePage = () => {
  const {
    thunkDispatch,
    storeState: { Profile, Auth, AppOption },
  } = useRedux();
  const navigate = useNavigate();
  const [payload, setPayload] = React.useState({
    closingKasir: "",
    note: "",
  });
  const [submit, setSubmit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(""); //eslint-disable-line
  const [closingSystem, setClosingSystem] = React.useState(0);
  const [dataClosing, setDataClosing] = React.useState<AmountRespond>();
  const [modal, setModal] = React.useState(false);

  const storeNameId =
    Auth.data &&
    Auth.data.profile_data &&
    Auth.data.profile_data.store_access &&
    Auth.data.profile_data.store_access.data_store &&
    Auth.data.profile_data.store_access.data_store.length !== 0
      ? AppOption && AppOption.selected_store && AppOption.selected_store.id
        ? Auth.data.profile_data.store_access.data_store.find(
            (element: any) => element.store_id === AppOption.selected_store.id
          )
        : null
      : null;

  useEffect(() => {
    let dataPayload = {
      store_id: AppOption?.selected_store?.id ?? "",
      token: Auth?.data?.access_token ?? "",
    };
    thunkDispatch(getAmountCash(dataPayload))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          if (res && res.data.data) {
            setDataClosing(res.data.data);
            setClosingSystem(Number(res.data.data.total_amount_by_system));
          }
        }
      });
  }, [thunkDispatch, AppOption, Auth]);

  const handleSubmitPos = (e: any) => {
    e.preventDefault();
    setSubmit(true);
    setLoading(true);
    if (payload && payload.closingKasir) {
      if (
        Number(closingSystem) > Number(payload.closingKasir) &&
        !payload.note
      ) {
        setLoading(false);
      } else {
        let dataPayload = {
          store_id: AppOption?.selected_store?.id ?? "",
          end_total_amount_cash: payload.closingKasir,
          notes: payload.note,
          token: Auth?.data?.access_token ?? "",
        };
        thunkDispatch(postClosingPos(dataPayload))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              notify("success", "Menutup Kasir Berhasil");
              setLoading(false);
              setStatus("success");
              setModal(true);
              setTimeout(() => {
                handleLogout();
              }, 2000);
            } else if (res && res.status === "error") {
              setLoading(false);
              setStatus("error");
              notify("error", res.error.response.data.message);
            }
          });
      }
    }
  };

  const handleLogout = () => {
    thunkDispatch(AuthLogout())
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          removeAllState();
          notify("success", "Kamu telah berhasil keluar dari aplikasi!");
        } else if (res && res.status === "error") {
          if (res.error.response) {
            notify("error", res.error.response.data.message);
          }
        }
      })
      .catch((err) => notify("error", err.message));
  };

  return (
    <MainLayout background="#FFC535">
      <ModalSucces
        open={modal}
        handleClose={() => setModal(!modal)}
        textModal="Closing POS Berhasil"
        description="Terima Kasih"
      />
      <Box width={"100%"}>
        <StyledBox>
          <StyledContainter>
            <NormalText
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={"bold"}
              lineHeight={"24px"}
              margin={"0px 0 10px 0px"}
              color={"#153E45"}
            >
              Hi,{" "}
              {Profile &&
              Profile.data &&
              Profile.data.data &&
              Profile.data.data.full_name
                ? Profile.data.data.full_name.length > 15
                  ? Profile.data.data.full_name.substr(0, 15) + ".."
                  : Profile.data.data.full_name
                : Auth?.data?.profile_data?.full_name
                ? Auth?.data?.profile_data?.full_name.length > 15
                  ? Auth.data.profile_data.full_name.substr(0, 15) + ".."
                  : Auth?.data?.profile_data?.full_name
                : ""}
            </NormalText>
            <NormalText
              textAlign={"center"}
              fontSize={"16px"}
              lineHeight={"24px"}
              margin={"0px 0 0px 0px"}
              color={"#153E45"}
            >
              Kasir | {storeNameId ? storeNameId.store_name : ""}
            </NormalText>
          </StyledContainter>
        </StyledBox>
        <ContentContainer>
          <CardPos>
            <NormalText
              textAlign={"center"}
              fontSize={"15px"}
              lineHeight={"24px"}
              margin={"10px 0 10px 0"}
            >
              Tanggal :{" "}
              {dataClosing && dataClosing.date
                ? moment(dataClosing.date).format("DD/MM/YYYY")
                : moment().format("DD/MM/YYY")}
            </NormalText>
            <Line />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <NormalText
                  textAlign={"left"}
                  fontSize={"15px"}
                  lineHeight={"24px"}
                  margin={"0px 0px 0px 20px"}
                >
                  Opening POS
                </NormalText>
              </Grid>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <NormalText
                  textAlign={"center"}
                  fontSize={"15px"}
                  fontWeight={"bold"}
                  lineHeight={"24px"}
                  margin={"0px 0 0px 0"}
                >
                  {formatCurrency(dataClosing?.total_amount_opening_pos ?? 0)}
                </NormalText>
              </Grid>
            </Grid>
            <Line />
            <Grid
              container
              direction="row"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <NormalText
                  textAlign={"center"}
                  fontSize={"15px"}
                  lineHeight={"24px"}
                  margin={"0px 0 0px 0"}
                >
                  Closing by System
                </NormalText>
              </Grid>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <NormalText
                  textAlign={"center"}
                  fontSize={"15px"}
                  fontWeight={"bold"}
                  lineHeight={"24px"}
                  margin={"0px 0 0px 0"}
                >
                  {formatCurrency(closingSystem)}
                </NormalText>
              </Grid>
            </Grid>
          </CardPos>
          <NormalText
            textAlign={"center"}
            fontSize={"20px"}
            fontWeight={"bold"}
            lineHeight={"24px"}
            margin={"80px 0 20px 0"}
          >
            Closing POS
          </NormalText>
          <form noValidate onSubmit={handleSubmitPos}>
            <Stack direction={"column"} spacing={2}>
              <InputField
                label={"Closing by Kasir"}
                required
                type={"number"}
                onChange={(e: any) =>
                  setPayload({ ...payload, closingKasir: e.target.value })
                }
                validationText={"Isikan closing by kasir"}
                validationType={
                  submit && !payload.closingKasir ? "error" : undefined
                }
                placeholder={"Isikan closing by kasir"}
                fullBorder={true}
                isPrefixRupiah={true}
              />
              <InputField
                label="Catatan"
                required={
                  Number(closingSystem) > Number(payload.closingKasir)
                    ? true
                    : false
                }
                type={"text"}
                onChange={(e: any) =>
                  setPayload({ ...payload, note: e.target.value })
                }
                validationText={
                  Number(closingSystem) > Number(payload.closingKasir)
                    ? "Isikan Catatan Anda"
                    : ""
                }
                validationType={
                  Number(closingSystem) > Number(payload.closingKasir)
                    ? submit && !payload.note
                      ? "error"
                      : undefined
                    : undefined
                }
                placeholder="Catatan"
                fullBorder={true}
                height={"130px"}
              />
            </Stack>
            <Button
              type="submit"
              width={"100%"}
              margin="35px 0 0 0"
              variant={loading ? "secondary" : "primary"}
            >
              Tutup Kasir
            </Button>
            <Button
              type="submit"
              width={"100%"}
              margin="15px 0 0 0"
              onClick={() => navigate("/")}
              variant={loading ? "secondary" : "outline"}
            >
              Keluar
            </Button>
          </form>
        </ContentContainer>
      </Box>
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 200px;
  position: relative;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ContentContainer = styled.div`
  background: white;
  border-radius: 20px 20px 0px 0px;
  height: calc(100vh - 200px);
  width: 100%;
  bottom: 0;
  padding: 20px 20px 10px 20px;
`;

const CardPos = styled.div`
  position: absolute;
  width: 328px;
  height: 140px;
  transform: translateX(0);
  top: 138px;

  background: #ffffff;
  /* sandbox-shadow-grey */

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`;

const Line = styled.div`
  width: 298.01px;
  height: 0px;
  margin-left: 15px;
  border: 1px solid #cecece;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export default ProfilePage;
