import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import { ContentContainer } from "components/styled/layout.styled";
import { ReactComponent as LockIcon } from "assets/icons/lock_ic.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profileIcon.svg";
import { KeluarIcon } from "assets/icons";
import { Button } from "components/styled/button.styled";
import { useNavigate } from "react-router-dom";
import useRedux from "redux/useRedux";
import { AuthLogout } from "redux/auth";
import { notify, removeAllState } from "helper/general";
import ConfirmationModal from "components/Confirmation";

const ProfilePage = () => {
  const {
    thunkDispatch,
    storeState: { Auth, Profile },
  } = useRedux();
  const navigate = useNavigate();
  const [logout, setLogout] = React.useState(false);

  const handleLogout = () => {
    thunkDispatch(AuthLogout())
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          removeAllState();
          notify("success", "Kamu telah berhasil keluar dari aplikasi!");
        } else if (res && res.status === "error") {
          if (res.error.response) {
            notify("error", res.error.response.data.message);
          }
        }
      })
      .catch((err) => notify("error", err.message));
  };

  return (
    <MainLayout background="#FFC535" hasNavbar>
      <ConfirmationModal
        open={logout}
        title={"Apakah yakin ingin keluar?"}
        handleConfirm={() => handleLogout()}
        handleClose={() => setLogout(false)}
      />
      <Box width={"100%"}>
        <StyledBox>
          <StyledContainter>
            <NormalText
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={"bold"}
              lineHeight={"24px"}
              margin={"0px 0 40px 0"}
            >
              Profile
            </NormalText>
            <NormalText
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={"bold"}
              lineHeight={"24px"}
              margin={"0px 0 10px 0px"}
              color={"#153E45"}
              style={{ wordBreak: 'break-all' }}
            >
              Hi,{" "}
              {Profile &&
                Profile.data &&
                Profile.data.data &&
                Profile.data.data.full_name
                ? Profile.data.data.full_name.length > 15 ? Profile.data.data.full_name.substr(0, 15) + '..' : Profile.data.data.full_name
                : Auth?.data?.profile_data?.full_name ?? ""}
            </NormalText>
            <NormalText
              textAlign={"center"}
              fontSize={"16px"}
              lineHeight={"24px"}
              margin={"0px 0 0px 0px"}
              color={"#153E45"}
            >
              Kasir
            </NormalText>
            <AktifLabel>
              <NormalText
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
                lineHeight={"24px"}
                margin={"10px 0px 0px 0px"}
                padding={"5px 0 5px 0px"}
                color={"#153E45"}
              >
                {Auth.data &&
                  Auth.data.profile_data &&
                  Auth.data.profile_data.is_active
                  ? Auth.data.profile_data.is_active === "Active"
                    ? "Aktif"
                    : "Tidak Aktif"
                  : "Tidak Aktif"}
              </NormalText>
            </AktifLabel>
          </StyledContainter>
        </StyledBox>
        <ContentContainer height="60%">
          <NormalText
            textAlign={"left"}
            fontSize={"20px"}
            fontWeight={"bold"}
            lineHeight={"24px"}
            margin={"30px 0 40px 0"}
          >
            Update Profile
          </NormalText>
          <Button
            type="submit"
            width={"100%"}
            variant={"primary"}
            margin="35px 0 0 0"
            onClick={() => navigate("/update-profile")}
            textAlign="left"
          >
            <div style={{ flexDirection: "row" }}>
              <Icon>
                <ProfileIcon fill={"black"} />
              </Icon>{" "}
              Ubah Profile
            </div>
          </Button>
          <Button
            type="submit"
            width={"100%"}
            // variant={loading ? 'secondary' : ''}
            background="white"
            margin="35px 0 0 0"
            border="2px solid white"
            onClick={() => navigate("/update-password")}
            textAlign="left"
          >
            <div style={{ flexDirection: "row" }}>
              <Icon>
                <LockIcon fill={"black"} />
              </Icon>{" "}
              Ubah Password
            </div>
          </Button>
          <Button
            type="submit"
            width={"100%"}
            variant={"outline"}
            margin="35px 0 0 0"
            onClick={() => setLogout(true)}
          >
            <div style={{ flexDirection: "row" }}>
              <Icon>
                <img src={KeluarIcon} alt={"Input Icon"} />
              </Icon>
              Keluar
            </div>
          </Button>
        </ContentContainer>
      </Box>
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 250px;
  position: relative;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Icon = styled.i`
  margin: 7px 10px 10px 15px;
  color: black;
`;

const AktifLabel = styled.div`
  max-width: 120px;
  height: 40px;
  background: #ffee8e;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  margin-left: 15%;
`;

export default ProfilePage;
