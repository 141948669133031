import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ProductState,
  ProductProps,
  ProductAddonState,
  ProductAddonsProps,
  ProductCategoryListInterfaceState,
  StoreProductProps,
  AddStoreProductPayload,
  EditStoreProductPayload,
  StoreProductDetailState,
} from './types';
import { getToken } from 'helper/general';
import axios from 'axios';

export const getProductList = createAsyncThunk(
  'product/list',
  async ({ offset, limit, keyword, store_id, category_id }: ProductProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/product-list?offset=${
          offset ?? 0
        }&limit=${limit ?? 10}&store_id=${store_id}${
          category_id ? `&category_id=${category_id}` : ''
        }${keyword ? `&keyword=${keyword}` : ''}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProductState;
    }
  }
);

export const getAdditionalList = createAsyncThunk(
  'product/additional/get',
  async ({ product_id, store_id }: ProductAddonsProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/product/additional?product_id=${product_id}&store_id=${store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProductAddonState;
      } else {
        return {
          data: [],
          status: 'empty',
          error: null,
        } as unknown as ProductAddonState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProductAddonState;
    }
  }
);

export const getCategoryList = createAsyncThunk(
  'product/category/list',
  async () => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/category-list`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProductCategoryListInterfaceState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProductCategoryListInterfaceState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProductCategoryListInterfaceState;
    }
  }
);

export const getStoreProducts = createAsyncThunk(
  'storeProduct/list',
  async ({ offset, limit, store_id, merchant_id }: StoreProductProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        token: getToken(),
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/product/list?merchant_id=${merchant_id}&store_id=${store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProductState;
    }
    //https://sandbox-api.stagingapps.net/pos/product/list?merchant_id=142&store_id=47
  }
);

export const addStoreProduct = createAsyncThunk(
  'storeProduct/add',
  async (props: AddStoreProductPayload) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/pos/product',
        props,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProductState;
    }
  }
);

export const getStoreProduct = createAsyncThunk(
  'storeProduct/detail',
  async (id: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/product/${id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as StoreProductDetailState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as StoreProductDetailState;
      }
    } catch (error) {
      if (error)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: error,
        } as unknown as StoreProductDetailState;
    }
  }
);

export const editStoreProduct = createAsyncThunk(
  'storeProduct/edit',
  async (props: EditStoreProductPayload) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const data = { ...props };
      delete data.product_id;

      const response = await axios.put(
        process.env.REACT_APP_API_URL + '/pos/product/' + props.product_id,
        data,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProductState;
    }
  }
);
