import { useState, SyntheticEvent, useEffect } from 'react';
import { onlyAlphaNumeric } from 'helper/general';
import CurrencyFormat from 'react-currency-format';
import MainLayout from 'components/Layouts/MainLayout';
import { Box, FormGroup, TextField } from '@mui/material';
import Header from 'components/Header/Header';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from 'components/Accordion/Accordion';
import InputField from 'components/Form/InputField';
import Switch from '@mui/material/Switch';
import { NormalText } from 'components/styled/text.styled';
import { Button } from 'components/styled/button.styled';
import Dropdown, { OptionProps } from 'components/Form/Dropdown';
import { uploadImage } from 'helper/uploadFile';
import { toast } from 'react-toastify';
import UploadProductPhotoItem from 'components/Item/UploadProductPhotoItem';
import useRedux from 'redux/useRedux';
import {
  categoryList,
  SubCategoryInterface,
  SubsCategoryInterface,
  CategoryInterface,
} from 'redux/category';
import { brandList } from 'redux/brand';
import { useParams } from 'react-router-dom';
import {
  ProductPayloadProps,
  getStoreProduct,
  EditStoreProductPayload,
  editStoreProduct,
  StoreProductInterface,
} from 'redux/product';
import { useNavigate } from 'react-router-dom';
import { updatePrice } from 'redux/cart/cart.reducers';

const ProductEditPage = () => {
  const navigate = useNavigate();
  const {
    storeState: { Brand, Category, AppOption },
    thunkDispatch,
  } = useRedux();

  const { id } = useParams();

  const [subCategoryList, setSubCategoryList] = useState<
    SubCategoryInterface[]
  >([]);
  const [subsCategoryList, setSubsCategoryList] = useState<
    SubsCategoryInterface[]
  >([]);

  const [productPayload, setProductPayload] = useState<ProductPayloadProps>({
    product_name: '',
    product_id: 0,
    no_sku: '',
    category_id: 0,
    sub_category_id: 0,
    subs_category_id: 0,
    brand_id: 0,
    product_detail: {
      product_type: '',
      is_customizable: false,
      product_price: '',
      discount_type: 'percent',
      discount: '0',
      product_price_after_discount: '',
      product_image_main: '',
      product_image_1: '',
      product_image_2: '',
      product_image_3: '',
      product_image_4: '',
    },
    product_description: {
      short_description: '',
      long_description: '',
    },
    product_store: [],
  });

  const [submit, setSubmit] = useState(false);

  const [loadingImg, setLoadingImg] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [images, setImages] = useState([
    { id: 1, file: '', main: true },
    { id: 2, file: '', main: false },
    { id: 3, file: '', main: false },
    { id: 4, file: '', main: false },
    { id: 5, file: '', main: false },
  ]);

  const [expanded, setExpanded] = useState<string | false>('panel0');

  const producttype = [
    {
      value: 'MAIN',
      label: 'Main',
    },
    {
      value: 'ADDITIONAL',
      label: 'Additional',
    },
  ];

  const discounttype = [
    {
      value: 'percent',
      label: 'Persentase',
    },
    {
      value: 'discount',
      label: 'Potongan Harga',
    },
  ];

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const reformatResponse = (data: StoreProductInterface) => {
    const findStoreData =
      data.productStore && data.productStore.length > 0
        ? data.productStore.find(
            (store: any) => store.store_id === AppOption.selected_store.id
          )
        : null;

    const formatted = {
      product_id: data.product_id,
      product_name: data.product_name,
      no_sku: data.no_sku,
      category_id: data.category.id,
      sub_category_id: data.sub_category.id,
      subs_category_id: data.subs_category.id,
      brand_id: data.brand.id,
      product_detail: {
        product_type: data.product_detail.product_type,
        is_customizable: data.product_detail.is_customizable,
        product_price:
          findStoreData && findStoreData.store_price
            ? findStoreData.store_price.toString()
            : '0',
        discount_type:
          findStoreData && findStoreData.discount_type
            ? findStoreData.discount_type
            : 'percent',
        discount:
          findStoreData && findStoreData.discount
            ? findStoreData.discount.toString()
            : '0',
        product_price_after_discount:
          findStoreData && findStoreData.final_price
            ? findStoreData.final_price.toString()
            : '0',
        product_image_main: data.product_detail.product_image_main,
        product_image_1: data.product_detail.product_image_1,
        product_image_2: data.product_detail.product_image_2,
        product_image_3: data.product_detail.product_image_3,
        product_image_4: data.product_detail.product_image_4,
      },
      product_description: {
        short_description: data.product_description.short_description,
        long_description: data.product_description.long_description,
      },
      product_store: [],
    };
    return formatted;
  };

  const initializeData = async () => {
    try {
      await thunkDispatch(brandList()).unwrap();
      const categoryData = await thunkDispatch(categoryList()).unwrap();

      const detailProduct = await thunkDispatch(getStoreProduct(id)).unwrap();

      if (categoryData && detailProduct) {
        setProductPayload(reformatResponse(detailProduct.data.data));
        setSubCategory(categoryData.data.data, detailProduct.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateData = () => {
    if (
      productPayload.product_name &&
      productPayload.product_name.length <= 50 &&
      productPayload.no_sku &&
      onlyAlphaNumeric(productPayload.no_sku) &&
      productPayload.no_sku.length <= 50 &&
      productPayload.category_id &&
      productPayload.sub_category_id &&
      productPayload.subs_category_id &&
      productPayload.brand_id &&
      productPayload.product_detail.product_type &&
      productPayload.product_detail.product_price &&
      String(productPayload.product_detail.product_price).length <= 15 &&
      productPayload.product_description.short_description &&
      productPayload.product_description.short_description.length <= 150 &&
      productPayload.product_description.long_description
    ) {
      return true;
    } else {
      return false;
    }
  };

  const payloadConversion = (data: ProductPayloadProps) => {
    let payload: EditStoreProductPayload = {
      product_id: id,
      no_sku: data.no_sku,
      product_name: data.product_name,
      category_id: data.category_id,
      sub_category_id: data.sub_category_id,
      subs_category_id: data.subs_category_id,
      brand_id: data.brand_id,
      product_detail: {
        product_type: data.product_detail.product_type,
        is_customizable: data.product_detail.is_customizable,
        product_image_main: data.product_detail.product_image_main,
        product_image_1: data.product_detail.product_image_1,
        product_image_2: data.product_detail.product_image_2,
        product_image_3: data.product_detail.product_image_3,
        product_image_4: data.product_detail.product_image_4,
      },
      product_description: {
        short_description: data.product_description.short_description,
        long_description: data.product_description.long_description,
      },
      product_store: {
        store_id:
          AppOption && AppOption.selected_store && AppOption.selected_store.id
            ? AppOption.selected_store.id.toString()
            : '',
        store_price: Number(data.product_detail.product_price),
        discount_type: data.product_detail.discount_type,
        discount: Number(data.product_detail.discount),
        final_price: Number(data.product_detail.product_price_after_discount),
        is_active: true,
      },
    };
    return payload;
  };

  const setSubCategory = (
    categoryData: CategoryInterface[],
    productData: StoreProductInterface
  ) => {
    let subCategory: SubCategoryInterface[] = [];
    let subsCategory: SubsCategoryInterface[] = [];

    subCategory = categoryData?.find(
      (x: CategoryInterface) => x.id === productData.category.id
    )?.subCategory!;

    if (subCategory && subCategory.length > 0) {
      subsCategory = subCategory.find(
        (x) => x.id === productData.sub_category.id
      )?.subsCategory!;
    }

    setSubCategoryList(subCategory);
    setSubsCategoryList(subsCategory);
  };

  interface UploadProps {
    file: any;
    key:
      | 'product_image_main'
      | 'product_image_1'
      | 'product_image_2'
      | 'product_image_3'
      | 'product_image_4';
    index: number;
  }

  const handleUploadFile = async ({ key, file, index }: UploadProps) => {
    var img = new Image();
    img.src = window.URL.createObjectURL(file);

    img.onload = async function () {
      let naturalImage = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };

      if (naturalImage.width <= 1000 && naturalImage.height <= 1000) {
        let formdata = new FormData();
        let loadingUpload = [...loadingImg];
        loadingUpload[index] = true;
        setLoadingImg(loadingUpload);
        formdata.append('image_count', '1');
        formdata.append('image-1', file);
        await uploadImage(formdata, 'product')
          .then((res) => {
            if (res.status === 200) {
              setLoadingImg([false, false, false, false, false]);
              var temp = { ...productPayload };
              temp.product_detail[key] = res.data.data['image-1'];
              setProductPayload(temp);
            }
            setLoadingImg([false, false, false, false, false]);
          })
          .catch((err) => {
            setLoadingImg([false, false, false, false, false]);
            toast.warning('Gagal mengupload image');
          });
      } else {
        toast.warning('Maksimum dimensi foto produk 1000x1000 pixel');
      }
    };
  };

  const getTotalDisc = () => {
    if (
      productPayload.product_detail.product_price &&
      productPayload.product_detail.discount_type
    ) {
      let type = productPayload.product_detail.discount_type;
      let price = Number(productPayload.product_detail.product_price);
      let disc = Number(productPayload.product_detail.discount);
      let totalDisc = 0;
      let afterPrice = price;
      if (type === 'percent') {
        totalDisc = price * (disc / 100);
        afterPrice = price - totalDisc;
      } else {
        afterPrice = price - disc;
      }
      setProductPayload({
        ...productPayload,
        product_detail: {
          ...productPayload.product_detail,
          product_price_after_discount: afterPrice.toString(),
        },
      });
    }
  };

  const updatePriceProductInCart = (props: ProductPayloadProps) => {
    const { product_id, product_name, product_detail } = props;
    const { product_price_after_discount, product_image_main } = product_detail;
    let data = {
      product_name: product_name,
      product_image: product_image_main,
      product_id: Number(product_id),
      price: Number(product_price_after_discount),
    };

    thunkDispatch(updatePrice(data));
  };

  const handledeleteImage = (key: any) => {
    setImages(
      images.map((image) => {
        if (image.id === key) {
          return {
            ...image,
            file: '',
          };
        } else {
          return image;
        }
      })
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmit(true);
    if (!validateData()) {
      return;
    }
    thunkDispatch(editStoreProduct(payloadConversion(productPayload)))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          navigate('/product/list');
          toast.success('Produk berhasil diperbarui');
          setSubmit(false);
          updatePriceProductInCart(productPayload);
        } else {
          toast.error('Produk gagal diperbarui!');
        }
      });
  };

  useEffect(() => {
    if (
      Number(productPayload.product_detail.discount) > 100 &&
      productPayload.product_detail.discount_type === 'percent'
    ) {
      setProductPayload({
        ...productPayload,
        product_detail: {
          ...productPayload.product_detail,
          discount: '99',
        },
      });
    }
  }, [productPayload, productPayload.product_detail.discount_type]);

  useEffect(() => {
    getTotalDisc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productPayload.product_detail.product_price,
    productPayload.product_detail.discount,
    productPayload.product_detail.discount_type,
  ]);

  useEffect(() => {
    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout hideBackgroundImage>
      <Box height={'100vh'} width={'100%'} sx={{ overflowY: 'hidden' }}>
        <Box height={'100%'} width={'100%'} sx={{ overflowY: 'scroll' }}>
          <Header pageName="Edit Produk" />
          <form onSubmit={handleSubmit} noValidate>
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                sx={{ backgroundColor: '#ffffff' }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <NormalText
                  fontWeight="bold"
                  color={
                    expanded === 'panel1' ? '#000000' : 'rgba(0, 0, 0, .5)'
                  }
                >
                  Nama & Kategori
                </NormalText>
              </AccordionSummary>

              <AccordionDetails>
                <InputField
                  label="No. SKU"
                  validationType={
                    (submit && !productPayload.no_sku) ||
                    (submit && productPayload.no_sku.length > 50) ||
                    (submit && !onlyAlphaNumeric(productPayload.no_sku))
                      ? 'error'
                      : undefined
                  }
                  validationText={
                    !productPayload.no_sku
                      ? 'Nomor SKU harus diisi'
                      : productPayload.no_sku.length > 50
                      ? 'Maksimum karakter untuk Nomor SKU adalah 50 karakter'
                      : !onlyAlphaNumeric(productPayload.no_sku)
                      ? 'Nomor SKU hanya dapat berisi alphanumeric'
                      : ''
                  }
                  placeholder="Masukan no. SKU"
                  value={productPayload?.no_sku}
                  onChange={(e: any) =>
                    setProductPayload({
                      ...productPayload,
                      no_sku: e.target.value,
                    })
                  }
                  required
                />

                <InputField
                  label="Nama Produk"
                  validationType={
                    (submit && !productPayload.product_name) ||
                    (submit && productPayload.product_name.length > 50)
                      ? 'error'
                      : undefined
                  }
                  validationText={
                    !productPayload.product_name
                      ? 'Nama produk harus diisi'
                      : productPayload.product_name.length > 50
                      ? 'Maksimum karakter untuk nama produk adalah 50 karakter'
                      : ''
                  }
                  placeholder="Masukan nama produk"
                  value={productPayload?.product_name}
                  boxStyle={{ margin: '1rem 0 0 0' }}
                  onChange={(e: any) =>
                    setProductPayload({
                      ...productPayload,
                      product_name: e.target.value,
                    })
                  }
                  required
                />

                <Dropdown
                  label={'Kategori'}
                  labelStyle="bold"
                  isRequired
                  validationType={
                    submit && !productPayload.category_id ? 'error' : undefined
                  }
                  validationText={'Kategori wajib diisi'}
                  value={productPayload.category_id}
                  options={
                    Category.data.data
                      ? Category.data.data.map((item) => {
                          return {
                            label: item.category_name,
                            value: item.id,
                          } as OptionProps;
                        })
                      : []
                  }
                  onChange={(value: any) => {
                    setProductPayload({
                      ...productPayload,
                      category_id: value,
                    });

                    let subcategory = Category.data.data?.find(
                      (x) => x.id === Number(value)
                    );
                    setSubCategoryList(subcategory?.subCategory ?? []);
                  }}
                />

                <Dropdown
                  label={'Sub Kategori'}
                  labelStyle="bold"
                  isRequired
                  validationType={
                    submit && !productPayload.sub_category_id
                      ? 'error'
                      : undefined
                  }
                  validationText={'Sub kategori wajib diisi'}
                  value={productPayload.sub_category_id}
                  options={
                    subCategoryList
                      ? subCategoryList.map((x) => {
                          return {
                            label: x.subcategory_name,
                            value: x.id,
                          } as OptionProps;
                        })
                      : []
                  }
                  onChange={(value: any) => {
                    setProductPayload({
                      ...productPayload,
                      sub_category_id: value,
                    });
                    let subcategory = subCategoryList?.find(
                      (x) => x.id === Number(value)
                    );
                    setSubsCategoryList(subcategory?.subsCategory ?? []);
                  }}
                />

                <Dropdown
                  label={'Sub Kategori Turunan'}
                  labelStyle="bold"
                  isRequired
                  validationType={
                    submit && !productPayload.subs_category_id
                      ? 'error'
                      : undefined
                  }
                  validationText={'Sub kategori turunan wajib diisi'}
                  value={productPayload.subs_category_id}
                  options={
                    subsCategoryList
                      ? subsCategoryList.map((x) => {
                          return {
                            label: x.subscategory_name,
                            value: x.id,
                          } as OptionProps;
                        })
                      : []
                  }
                  onChange={(value: any) => {
                    setProductPayload({
                      ...productPayload,
                      subs_category_id: value,
                    });
                  }}
                />

                <Dropdown
                  label={'Brand'}
                  labelStyle="bold"
                  isRequired
                  validationType={
                    submit && !productPayload.brand_id ? 'error' : undefined
                  }
                  validationText={'Brand wajib diisi'}
                  value={productPayload.brand_id}
                  options={
                    Brand.data.data
                      ? Brand.data.data.map((item) => {
                          return {
                            label: item.brand_name,
                            value: item.id,
                          } as OptionProps;
                        })
                      : []
                  }
                  onChange={(value: any) =>
                    setProductPayload({
                      ...productPayload,
                      brand_id: value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                sx={{ backgroundColor: '#ffffff' }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <NormalText
                  fontWeight="bold"
                  color={
                    expanded === 'panel2' ? '#000000' : 'rgba(0, 0, 0, .5)'
                  }
                >
                  Detail
                </NormalText>
              </AccordionSummary>
              <AccordionDetails>
                <Dropdown
                  label={'Tipe Produk'}
                  labelStyle="bold"
                  isRequired
                  validationType={
                    submit && !productPayload.product_detail.product_type
                      ? 'error'
                      : undefined
                  }
                  validationText={'Tipe produk wajib diisi'}
                  value={productPayload.product_detail.product_type}
                  options={producttype}
                  onChange={(value: any) =>
                    setProductPayload({
                      ...productPayload,
                      product_detail: {
                        ...productPayload.product_detail,
                        product_type: value,
                      },
                    })
                  }
                />

                <FormGroup sx={{ margin: '1rem 0 0 0' }}>
                  <NormalText fontWeight="bold" fontSize="14px">
                    Customizable
                  </NormalText>
                  <Switch
                    value={productPayload.product_detail.is_customizable}
                    checked={productPayload.product_detail.is_customizable}
                    onChange={() =>
                      setProductPayload({
                        ...productPayload,
                        product_detail: {
                          ...productPayload.product_detail,
                          is_customizable:
                            !productPayload.product_detail.is_customizable,
                        },
                      })
                    }
                  />
                </FormGroup>

                <NormalText fontSize={'14px'} fontWeight="bold">
                  Harga Produk (Rp) *
                </NormalText>

                <CurrencyFormat
                  customInput={InputField}
                  min={0}
                  thousandSeparator={true}
                  value={productPayload.product_detail.product_price}
                  onValueChange={(e: any) => {
                    setProductPayload({
                      ...productPayload,
                      product_detail: {
                        ...productPayload.product_detail,
                        product_price: e.value,
                      },
                    });
                  }}
                />
                {submit && !productPayload.product_detail.product_price ? (
                  <NormalText fontSize={'12px'} color={'#f12147'}>
                    Harga produk wajib diisi
                  </NormalText>
                ) : submit &&
                  Number(productPayload.product_detail.product_price) <= 0 ? (
                  <NormalText fontSize={'12px'} color={'#f12147'}>
                    Harga produk harus diatas 0
                  </NormalText>
                ) : null}

                <Dropdown
                  label={'Tipe Diskon'}
                  labelStyle="bold"
                  options={discounttype}
                  value={productPayload.product_detail.discount_type}
                  onChange={(value: any) =>
                    setProductPayload({
                      ...productPayload,
                      product_detail: {
                        ...productPayload.product_detail,
                        discount_type: value,
                      },
                    })
                  }
                />

                <InputField
                  label="Diskon"
                  placeholder={
                    productPayload.product_detail.discount_type
                      ? productPayload.product_detail.discount_type ===
                        'percent'
                        ? '%'
                        : 'Rp'
                      : 'Silahkan pilih tipe diskon'
                  }
                  value={productPayload?.product_detail.discount}
                  boxStyle={{ margin: '1rem 0 0 0' }}
                  onChange={(e: any) => {
                    let val = Number(e.target.value);
                    if (
                      productPayload.product_detail.discount_type === 'percent'
                    ) {
                      if (Number(val) >= 100) {
                        val = 99;
                      }
                    } else if (
                      productPayload.product_detail.discount_type === 'discount'
                    ) {
                      if (
                        val >
                        Number(productPayload.product_detail.product_price)
                      ) {
                        val = Number(
                          productPayload.product_detail.product_price
                        );
                      }
                    }

                    if (val < 0) {
                      val = 0;
                    }

                    setProductPayload({
                      ...productPayload,
                      product_detail: {
                        ...productPayload.product_detail,
                        discount: String(val),
                      },
                    });
                  }}
                />

                <NormalText
                  fontSize={'14px'}
                  fontWeight="bold"
                  margin="1rem 0 0 0"
                >
                  Harga produk setelah diskon (Rp)
                </NormalText>

                <CurrencyFormat
                  customInput={InputField}
                  readOnly
                  min={0}
                  thousandSeparator={true}
                  value={
                    productPayload.product_detail.product_price_after_discount
                  }
                  placeholder={
                    !productPayload.product_detail.product_price ||
                    !productPayload.product_detail.discount
                      ? 'Silahkan atur diskon produk'
                      : ''
                  }
                />

                <Box sx={{ margin: '1rem 0 0 0', width: '100%' }}>
                  <NormalText fontWeight="bold" fontSize="14px">
                    Foto Produk
                  </NormalText>
                  <Box
                    sx={{
                      overflowX: 'hidden',
                      width: '100%',
                      margin: '1rem 0 0 0',
                    }}
                  >
                    <Box
                      className="upload-product-photos-container"
                      sx={{
                        overflowX: 'scroll',
                        width: '100%',
                        display: 'flex',
                      }}
                    >
                      <UploadProductPhotoItem
                        key={'product_image_main'}
                        loading={loadingImg[0]}
                        main={true}
                        file={productPayload.product_detail.product_image_main}
                        onChange={(e: any) => {
                          let file = e.target.files[0];
                          if (file.size / 1024 <= 512) {
                            handleUploadFile({
                              key: 'product_image_main',
                              file: e.target.files[0],
                              index: 0,
                            }).then(() => null);
                          } else {
                            toast.warning(
                              'Ukuran file yang anda unggah melebihi batas maksimum 512KB'
                            );
                          }
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget={'product_image_main'}
                      />

                      <UploadProductPhotoItem
                        key={'product_image_1'}
                        loading={loadingImg[1]}
                        main={false}
                        file={productPayload.product_detail.product_image_1}
                        onChange={(e: any) => {
                          let file = e.target.files[0];
                          if (file.size / 1024 <= 512) {
                            handleUploadFile({
                              key: 'product_image_1',
                              file: e.target.files[0],
                              index: 1,
                            }).then(() => null);
                          } else {
                            toast.warning(
                              'Ukuran file yang anda unggah melebihi batas maksimum 512KB'
                            );
                          }
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget={'product_image_1'}
                      />

                      <UploadProductPhotoItem
                        key={'product_image_2'}
                        loading={loadingImg[2]}
                        main={false}
                        file={productPayload.product_detail.product_image_2}
                        onChange={(e: any) => {
                          let file = e.target.files[0];
                          if (file.size / 1024 <= 512) {
                            handleUploadFile({
                              key: 'product_image_2',
                              file: e.target.files[0],
                              index: 2,
                            }).then(() => null);
                          } else {
                            toast.warning(
                              'Ukuran file yang anda unggah melebihi batas maksimum 512KB'
                            );
                          }
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget={'product_image_2'}
                      />

                      <UploadProductPhotoItem
                        key={'product_image_3'}
                        loading={loadingImg[3]}
                        main={false}
                        file={productPayload.product_detail.product_image_3}
                        onChange={(e: any) => {
                          let file = e.target.files[0];
                          if (file.size / 1024 <= 512) {
                            handleUploadFile({
                              key: 'product_image_3',
                              file: e.target.files[0],
                              index: 3,
                            }).then(() => null);
                          } else {
                            toast.warning(
                              'Ukuran file yang anda unggah melebihi batas maksimum 512KB'
                            );
                          }
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget={'product_image_3'}
                      />

                      <UploadProductPhotoItem
                        key={'product_image_4'}
                        loading={loadingImg[4]}
                        main={false}
                        file={productPayload.product_detail.product_image_4}
                        onChange={(e: any) => {
                          let file = e.target.files[0];
                          if (file.size / 1024 <= 512) {
                            handleUploadFile({
                              key: 'product_image_4',
                              file: e.target.files[0],
                              index: 4,
                            }).then(() => null);
                          } else {
                            toast.warning(
                              'Ukuran file yang anda unggah melebihi batas maksimum 512KB'
                            );
                          }
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget={'product_image_4'}
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                sx={{ backgroundColor: '#ffffff' }}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <NormalText
                  fontWeight="bold"
                  color={
                    expanded === 'panel3' ? '#000000' : 'rgba(0, 0, 0, .5)'
                  }
                >
                  Deskripsi
                </NormalText>
              </AccordionSummary>
              <AccordionDetails>
                <InputField
                  label="Deskripsi Singkat"
                  required
                  placeholder="Deskripsi singkat"
                  value={productPayload?.product_description.short_description}
                  validationText={
                    !productPayload.product_description.short_description
                      ? 'Deskripsi singkat wajib diisi'
                      : productPayload.product_description.short_description
                          .length > 150
                      ? 'Maksimum karakter untuk deskripsi singkat adalah 150 karakter'
                      : ''
                  }
                  validationType={
                    (submit &&
                      !productPayload.product_description.short_description) ||
                    (submit &&
                      productPayload.product_description.short_description
                        .length > 150)
                      ? 'error'
                      : undefined
                  }
                  onChange={(e: any) =>
                    setProductPayload({
                      ...productPayload,
                      product_description: {
                        ...productPayload.product_description,
                        short_description: e.target.value,
                      },
                    })
                  }
                />

                <NormalText
                  fontSize="14px"
                  fontWeight="bold"
                  margin="1rem 0 0 0"
                >
                  Deskripsi Lengkap*
                </NormalText>
                <TextField
                  placeholder="Deskripsi Lengkap"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '0.8rem',
                      fontFamily: 'D-DIN',
                      padding: ' 2%',
                    },
                    '&:focus': {
                      borderColor: 'red',
                    },
                  }}
                  multiline
                  variant="standard"
                  fullWidth
                  required
                  value={productPayload.product_description.long_description}
                  onChange={(e: any) =>
                    setProductPayload({
                      ...productPayload,
                      product_description: {
                        ...productPayload.product_description,
                        long_description: e.target.value,
                      },
                    })
                  }
                />
                {submit &&
                !productPayload.product_description.long_description ? (
                  <NormalText fontSize={'12px'} color={'#f12147'}>
                    Deskripsi lengkap wajib diisi
                  </NormalText>
                ) : null}
              </AccordionDetails>
            </Accordion>

            <Box padding={'15px 16px'}>
              <Button
                type="submit"
                width={'100%'}
                variant={validateData() ? 'primary' : 'disabled'}
                margin="10px 0 0 0"
                disabled={!validateData()}
              >
                Simpan
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ProductEditPage;
