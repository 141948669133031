import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { Box, Stack } from '@mui/material';
import { AppLogo } from 'assets/images';
import styled from 'styled-components';
import { NormalText } from 'components/styled/text.styled';
import { ContentContainer } from 'components/styled/layout.styled';
import InputField from 'components/Form/InputField';
import { MailIcon } from 'assets/icons';
import { Button } from 'components/styled/button.styled';
import { useNavigate } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { AuthForgotPassword } from 'redux/auth';
import { notify, validateEmail } from 'helper/general';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);

  const handleSubmit = () => {
    setSubmit(true);
    if (email && validateEmail(email)) {
      setLoading(true);
      thunkDispatch(AuthForgotPassword(email))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            setSent(true);
            notify(
              'success',
              'Berhasil mengirimkan email pemulihan kata sandi, silahkan cek email anda'
            );
          } else {
            if (res?.error.response) {
              notify('error', res.error.response.data.message);
            }
          }
        })
        .catch((err) => notify('error', err.messsage))
        .finally(() => setLoading(false));
    }
  };

  return (
    <MainLayout background="#FFE39D">
      <Box width={'100%'}>
        <StyledBox>
          <StyledContainter>
            <img src={AppLogo} alt={'App Logo'} />
          </StyledContainter>
        </StyledBox>
        <ContentContainer>
          <NormalText
            textAlign={'center'}
            fontSize={'20px'}
            fontWeight={'bold'}
            lineHeight={'24px'}
            margin={'30px 0 0 0'}
          >
            Lupa Password?
          </NormalText>
          <NormalText
            textAlign={'center'}
            fontSize={'13px'}
            lineHeight={'24px'}
            margin={'0 0 40px 0'}
          >
            Masukan email anda
          </NormalText>
          <Stack direction={'column'} spacing={2}>
            <InputField
              inputIcon={MailIcon}
              label={'Email'}
              required
              onChange={(e: any) => setEmail(e.target.value)}
              type={'email'}
              placeholder={'Masukan Email Anda'}
              validationText={'Silahkan isi email yang valid'}
              validationType={
                (submit && !email) || (submit && !validateEmail(email))
                  ? 'error'
                  : undefined
              }
            />
          </Stack>
          {sent ? (
            <Button width="100%" variant={'secondary'} margin="110px 0 0 0">
              Email Terkirim
            </Button>
          ) : (
            <Button
              width="100%"
              variant={loading ? 'secondary' : 'primary'}
              margin="110px 0 0 0"
              onClick={() => (loading ? null : handleSubmit())}
            >
              {loading ? 'Tunggu Sebentar' : 'Selanjutnya'}
            </Button>
          )}
          <Button
            width="100%"
            variant="outline"
            margin="10px 0 0 0"
            onClick={() => navigate('/login')}
          >
            Kembali
          </Button>
          <NormalText
            fontSize="14px"
            textAlign={'center'}
            letterSpacing={'0.1px'}
            margin={'40px 0 0 0'}
          >
            2023 © All Reserved by Pagii Ordering
          </NormalText>
        </ContentContainer>
      </Box>
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 250px;
  position: relative;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default ForgotPasswordPage;
