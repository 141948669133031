import React, { useEffect } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { 
  Box, 
  Stack, 
  TextField, 
  Autocomplete, 
  Avatar 
} from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import { ContentContainer } from "components/styled/layout.styled";
import { Button } from "components/styled/button.styled";
import { useNavigate } from "react-router-dom";
import useRedux from "redux/useRedux";
// import { getOrder, OrderProps, getList } from 'redux/order';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getList, updateStatus, getOrder, OrderProps } from "redux/order";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchIcon, ArrowDownIcon } from "assets/icons";
import { ReactComponent as PeopleIcon } from "assets/icons/icon_people.svg";
import { ReactComponent as OrderIcon } from "assets/icons/ion_receipt-sharp.svg";
import { BillConfirmationIcon, YellowCircleCheckIcon } from "assets/icons";
import ConfirmationModal from "components/Confirmation";
import OrderLoader from "./Loader/item.loader";
import moment from "moment";
import { updateNotification } from "redux/notification/notification.reducer";
import { cancelOrder } from "redux/checkout";

const OrderPage = () => {
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption,
      Notification: { notifications },
    },
  } = useRedux();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  // const [data, setData] = React.useState<OrderProps[]>([]);
  const [limit, setLimit] = React.useState(100);
  const [openOption, setOpenOption] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState("name");

  const [listData, setListData] = React.useState<any>([]);
  // const [listDataPending, setListDataPending] = React.useState<any>([]);
  const [listStore, setListStore] = React.useState<any>([]);
  const [idSelectedStore, setIdSelectedStore] = React.useState<any>(0);
  const [activeStatus, setActiveStatus] = React.useState("NEW_ORDER");
  const [indexActiveStatus, setIndexActiveStatus] = React.useState(0);
  const [orderNumber, setOrderNumber] = React.useState("");
  const [remove, setRemove] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [removeSuccess, setRemoveSuccess] = React.useState(false);
  // const [updateStatusSelesai, setUpdateStatusSelesai] = React.useState(false);
  const listStatus = [
    {
      name: "Baru",
      status: "NEW_ORDER",
      update_name: "Proses",
      update_status: "PROCESS",
    },
    {
      name: "Proses",
      status: "PROCESS",
      update_name: "Ready",
      update_status: "READY_TO_PICKUP",
    },
    {
      name: "Siap dipickup",
      status: "READY_TO_PICKUP",
      update_name: "Dikirim",
      update_status: "DELIVERY",
    },
    {
      name: "Dikirim",
      status: "DELIVERY",
      update_name: "Selesai",
      update_status: "CLOSED",
    },
    {
      name: "Selesai",
      status: "CLOSED",
      update_name: "",
      update_status: "",
    },
    {
      name: "Batal",
      status: "CANCELED",
      update_name: "",
      update_status: "",
    },
    {
      name: "Pending",
      status: "PENDING",
      update_name: "Batal",
      update_status: "CANCELED",
    },
  ];

  const lessThanSixHours = (timestamp: any) => {
    let a = moment();
    let b = moment(timestamp);
    let diffent = a.diff(b);
    return diffent < 1000 * 60 * 60 * 6 ? true : false;
  };

  const fetchMoreData = () => {
    setTimeout(() => {
      setLimit(limit + 10);
    }, 1500);
  };

  // count the number of new orders in the last few hours
  const countNewNotif = (prop: any) => {
    var new_order = prop.filter((order: any) =>
      lessThanSixHours(order.payment_date)
    ).length;

    if (activeStatus === "NEW_ORDER") {
      let prop: { count_notif: number; count_new_order: number } = {
        count_notif: notifications.count_notif ? notifications.count_notif : 0,
        count_new_order: new_order,
      };
      thunkDispatch(updateNotification(prop));
    }
  };

  //get list order
  const getListData = () => {
    if (idSelectedStore && AppOption.selected_store.id) {
      let dataPayload = {
        store_id: AppOption.selected_store.id,
        // store_id: idSelectedStore ?? AppOption.selected_store.id,
        limit: limit,
        customer_name: selectOption === "name" ? search : "",
        order_number: selectOption === "order" ? search : "",
        filter: search,
        token: Auth?.data?.access_token ?? "",
        status: activeStatus,
      };
      thunkDispatch(getList(dataPayload))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            if (res.data && res.data.data) {
              // filter for status pending 
              if( activeStatus === "PENDING") {
                setListData(res.data.data.filter((data: any) => data.status !== "CANCELED"));
              }else{
                setListData(res.data.data)
              }
              setLoading(false);
              //refresh new notif
              countNewNotif(res.data.data);
            }
          }
        })
        .catch((err: any) => console.log(err));
    }
  };

  useEffect(() => {
    setListStore(Auth.data.profile_data.store_access?.data_store);
    setIdSelectedStore(AppOption.selected_store.id);
  }, [Auth, AppOption]);

  useEffect(() => {
    // setLoading(true);
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    thunkDispatch,
    AppOption,
    search,
    Auth,
    limit,
    selectOption,
    activeStatus,
    idSelectedStore,
    listData,
  ]);

  const getRefreshData = () => {
    setLoading(true);
    getListData();
  };

  const handleStatus = () => {
    let dataObject = {
      data: {
        order_number: orderNumber,
        status_order: listStatus[indexActiveStatus].update_status,
      },
      token: Auth?.data?.access_token ?? "",
    };
    thunkDispatch(updateStatus(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setRemoveSuccess(true);
          getRefreshData();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = () => {
    thunkDispatch(cancelOrder({ order_number: orderNumber }))
      .unwrap()
      .then(async (res) => {
        if (res && res.status === "success") {
          setRemoveSuccess(true);
          getRefreshData();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <MainLayout background="#FFC535" hasNavbar activePage="/order">
      <ConfirmationModal
        open={remove}
        icon={BillConfirmationIcon}
        titleStyle={{ margin: "15px 0" }}
        title={`Apakah anda yakin ingin update status menjadi ${listStatus[indexActiveStatus].update_name} ?`}
        handleClose={() => setRemove(false)}
        handleConfirm={() => {
          listStatus[indexActiveStatus].update_status === "CANCELED" 
            ? handleCancel()
            : handleStatus()
          setRemove(false);
        }}
      />
      <ConfirmationModal
        open={removeSuccess}
        icon={YellowCircleCheckIcon}
        titleStyle={{ margin: "15px 0" }}
        title={"Status Pesanan berhasil diupdate"}
        handleClose={() => setRemoveSuccess(false)}
        handleConfirm={() => null}
        noAction
      />
      <Box width={"100%"}>
        <StyledBox>
          <StyledContainter>
            <NormalText
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={"400"}
              fontFamily={"D-DIN"}
              fontStyle={"normal"}
              lineHeight={"24px"}
              margin={"0px 0 40px 0"}
            >
              {`Pesanan Dengan Status ${listStatus[indexActiveStatus].name}`}
            </NormalText>
            <Stack direction={"column"} alignItems={"center"} spacing={1}>
              {listStatus && listStore && listStore.length > 1 && (
                <Autocomplete
                  id="combo-box-demo"
                  //mengurutkan list toko dari toko yang sedang dipilih dahulu
                  options={
                    listStore && listStore.length > 0
                      ? listStore
                          .slice()
                          .sort((a: { store_id: number | null }) => {
                            return a.store_id === AppOption.selected_store.id
                              ? -1
                              : 1;
                          })
                      : []
                  }
                  getOptionLabel={(option) => option.store_name || ""}
                  onChange={(event: any, value: any) => {
                    setIdSelectedStore(value.store_id);
                  }}
                  disableClearable
                  sx={{ width: "200px" }}
                  defaultChecked
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ fontSize: 12 }}
                      size="small"
                      placeholder={"Toko Default"}
                    />
                  )}
                />
              )}
            </Stack>
          </StyledContainter>
        </StyledBox>
        <ContentContainer paddingBottom="10px">
          <Box>
            <Icon>
              <img src={SearchIcon} alt={"search_icon"} />
            </Icon>
            <Search
              placeholder={"Cari Nama / Nomor Transaksi"}
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <IconArrow onClick={() => setOpenOption(!openOption)}>
              <img src={ArrowDownIcon} alt={"arow_icon"} />
            </IconArrow>
          </Box>
          {openOption && (
            <Box sx={{ position: "absolute", zIndex: 5, width: "89%" }}>
              <BoxSelect
                style={{
                  backgroundColor: selectOption === "name" ? "#FFC535" : "#fff",
                  borderRadius: "5px 5px 0px 0px",
                }}
                onClick={() => setSelectOption("name")}
              >
                <PeopleIcon
                  fill={selectOption === "name" ? "#5B5B5B" : "#FFC535"}
                />
                <NormalText
                  textAlign={"left"}
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  lineHeight={"24px"}
                  margin={"0px 0 40px 10px"}
                  color={selectOption === "name" ? "#5B5B5B" : "#FFC535"}
                >
                  Nama Pelanggan
                </NormalText>
              </BoxSelect>
              <BoxSelect
                style={{
                  backgroundColor:
                    selectOption === "order" ? "#FFC535" : "#fff",
                  boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.12)",
                  borderRadius: "0px 0px 5px 5px",
                }}
                onClick={() => setSelectOption("order")}
              >
                <OrderIcon
                  fill={selectOption === "order" ? "#5B5B5B" : "#FFC535"}
                />
                <NormalText
                  textAlign={"left"}
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  lineHeight={"24px"}
                  margin={"0px 0 40px 10px"}
                  color={selectOption === "order" ? "#5B5B5B" : "#FFC535"}
                >
                  Nomor Transaksi
                </NormalText>
              </BoxSelect>
            </Box>
          )}
          <Box margin={"11px 0 0 0"} justifyContent={"center"}>
            <Navigation>
              <UnorderList>
                {listStatus &&
                  listStatus.map((item, index) => (
                    <ListItem
                      key={index}
                      active={item.status === activeStatus}
                      onClick={() => {
                        setActiveStatus(item.status);
                        setIndexActiveStatus(index);
                      }}
                    >
                      <Stack direction={"row"} justifyContent={"center"}>
                        <NormalText margin="0 0px" fontSize="14px">
                          {item.name}
                        </NormalText>
                        {/* show the number of new order notifications for default store */}
                        {item.status === "NEW_ORDER" &&
                          idSelectedStore === AppOption.selected_store.id &&
                          notifications.count_new_order &&
                          notifications.count_new_order !== 0 ? (
                          <Avatar
                            sx={{
                              width: 14,
                              height: 14,
                              bgcolor: "#FF7676",
                              margin: "4px 0 0 4px",
                            }}
                          >
                            <NormalText fontSize="12px">
                              {notifications.count_new_order}
                            </NormalText>
                          </Avatar>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </ListItem>
                  ))}
              </UnorderList>
            </Navigation>
          </Box>
          <Navigation>
            {loading ? (
              <OrderLoader />
            ) : (
              <>
                {listData.length !== 0 ? (
                  <InfiniteScroll
                    dataLength={limit}
                    next={() => fetchMoreData()}
                    hasMore={true}
                    loader={
                      <NormalText
                        textAlign={"center"}
                        fontSize={"16px"}
                        fontWeight={"bold"}
                        lineHeight={"24px"}
                        margin={"0px 0 40px 0"}
                      >
                        Load More...
                      </NormalText>
                    }
                  >
                    {listData.length !== 0 &&
                      activeStatus === "NEW_ORDER" &&
                      notifications.count_new_order &&
                      notifications.count_new_order !== 0 ? (
                      <NormalText
                        fontFamily="D-DIN"
                        color="#5B5B5B"
                        fontSize="16px"
                        fontWeight="400"
                      >
                        Kamu memiliki{" "}
                        <b>{notifications.count_new_order} Pesanan </b> Terbaru
                      </NormalText>
                    ) : (
                      activeStatus === "NEW_ORDER" && (
                        <NormalText
                          fontFamily="D-DIN"
                          color="#5B5B5B"
                          fontSize="16px"
                          fontWeight="400"
                        >
                          Kamu <b>belum memiliki pesanan </b> Terbaru
                        </NormalText>
                      )
                    )}
                    {listData.map((item: any, i: any) => (
                      <Box justifyContent={"center"}>
                        <ListOrder
                          // menampilkan warna berbeda untuk pesanan baru di status baru
                          newOrder={
                            activeStatus === "NEW_ORDER" &&
                            lessThanSixHours(item.payment_date)
                              ? true
                              : false
                          }
                        >
                          <div
                            onClick={() => {
                              navigate(`/detail-order/${item.order_number}`);
                            }}
                            style={{ width: "65%", margin: "10px" }}
                          >
                            <NormalText
                              textAlign={"left"}
                              fontWeight={"bold"}
                              fontSize={"16px"}
                              lineHeight={"16px"}
                              margin={"0px 0px 5px 0px"}
                            >
                              {item.order_number}
                            </NormalText>
                            <NormalText
                              textAlign={"left"}
                              fontWeight={"bold"}
                              fontSize={"15px"}
                              lineHeight={"16px"}
                              margin={"5px 0px 0px 0px"}
                            >
                              {item.customer_name.length > 15
                                ? item.customer_name.substr(0, 16) + "..."
                                : item.customer_name}
                            </NormalText>
                          </div>
                          {listStatus[indexActiveStatus].update_status !==
                          "" ? (
                            <div style={{ width: "35%", margin: "10px" }}>
                              <Button
                                type="submit"
                                height={"32px"}
                                variant={"primary"}
                                margin="5px 0px 0px 0px"
                                onClick={() => {
                                  setOrderNumber(item.order_number);
                                  setRemove(true);
                                }}
                                textAlign="center"
                              >
                                {/* {listStatus[indexActiveStatus].status === "READY_TO_PICKUP" && item.order_type === "DELIVERY"
                                  ? listStatus[indexActiveStatus].update_name
                                  : "Selesai"
                                } */}
                                {listStatus[indexActiveStatus].update_name}
                              </Button>
                            </div>
                          ) : (
                            <div style={{ width: "35%", margin: "10px" }}>
                              <Button
                                type="submit"
                                height={"32px"}
                                variant={"primary"}
                                margin="5px 0px 0px 0px"
                                onClick={() => {
                                  navigate(
                                    `/detail-order/${item.order_number}`
                                  );
                                }}
                                textAlign="center"
                              >
                                Detail
                              </Button>
                            </div>
                          )}
                        </ListOrder>
                      </Box>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <NormalText
                    textAlign={"center"}
                    fontSize={"18px"}
                    fontWeight={"bold"}
                    lineHeight={"24px"}
                    margin={"30px 0 40px 0px"}
                  >
                    Data Tidak Tersedia
                  </NormalText>
                )}
                <Box marginTop={"130px"} />
              </>
            )}
          </Navigation>
        </ContentContainer>
      </Box>
    </MainLayout>
  );
};

const UnorderList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  width: 100%;
  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

interface ListProps {
  active?: boolean;
  background?: string;
}

const ListItem = styled.li<ListProps>`
  transition: ease-in-out 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-family: "D-DIN";
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: "black";
  background-color: ${({ active }) => (active ? "#FFF0CC" : "none")};
  border-radius: 15px;
  border: ${({ active }) =>
    active ? "1px solid #FEF0CC" : "1px solid #ffc536"};
  text-underline-offset: 6px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  text-align: center;
  min-width: 120px;
  margin: 0 7px 4px 0;

  :hover {
    font-weight: bold;
  }
`;

const Navigation = styled.div`
  width: 100%;
  transition: ease-in-out 0.5s;
  margin-bottom: -10px;
  text-align: left;
  align-items: center;
  height: 100%;
  position: relative;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledBox = styled(Box)`
  height: 200px;
  position: relative;
`;

const StyledContainter = styled(Box)`
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface ListOrderProps {
  newOrder?: boolean;
}

const ListOrder = styled.div<ListOrderProps>`
  width: 100%;
  height: 60px;
  border: ${({ newOrder }) => (newOrder ? "" : "1px solid #bababa")};
  border-radius: 5px;
  flex-direction: row;
  display: flex;
  margin-top: 15px;
  background-color: ${({ newOrder }) => (newOrder ? "#FFF0CC" : "none")};
`;

const Icon = styled.i`
  position: absolute;
  margin: 10px 10px 10px 16px;
`;

const IconArrow = styled.i`
  position: absolute;
  margin: 10px 0px 0px -30px;
`;

const Search = styled.input`
  width: 100%;
  border: none;
  padding: 0 10px 0 56px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #bababa;
  border-style: solid;
  border-radius: 5px;
  height: 45px;
  font-family: "D-DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  letter-spacing: 0.5px;

  :focus,
  :hover {
    border-color: black;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #bababa;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bababa;
  }

  ::placeholder {
    color: #bababa;
  }
`;

const BoxSelect = styled.div`
  width: 100%;
  border: none;
  padding: 0 10px 0 56px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #fff;
  border-style: solid;
  height: 45px;
  flex-direction: row;
  display: flex;
  padding-top: 10px;
`;

export default OrderPage;
