import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import { Stack } from "@mui/material";
import CounterButton from "components/Counter";
import { formatCurrency } from "helper/general";
import { MiniTimesGrayIcon } from "assets/icons";
import React from "react";
import NoPhoto from "assets/images/no-product-photo.svg"


export interface ICartItem {
  image: string;
  price: number;
  category: string;
  name: string;
  qty: number;
  onIncrease: any;
  onDecrease: any;
  onRemove: any;
  additional?: ICartItemAddons[];
}

export interface ICartItemAddons {
  name: string;
  price: number;
  qty: number;
}

const CartItem = ({
  image,
  price,
  category,
  name,
  qty,
  onIncrease,
  onDecrease,
  additional,
  onRemove,
}: ICartItem) => { 
  let totalPriceAdditional = 0 
  if(additional && additional.length > 0){
    additional.forEach((addon) => {
       totalPriceAdditional += addon.price * addon.qty
    })
  }

  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Container>
      <XButton src={MiniTimesGrayIcon} alt={"remove_ic"} onClick={onRemove} />
      <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={1}>
          {imageError ? (
            <img
              src={NoPhoto}
              alt={"Product"}
              style={{ width: '55px', height: '50px', borderRadius: "10px", maxHeight: "50px" }}
            />
          ) : (
            <img
              src={image}
              alt={"Product"}
              style={{ width: '55px', height: '50px', borderRadius: "10px", maxHeight: "50px" }}
              onError={handleImageError}
            />
          )}
          <Stack direction={"column"} justifyContent={"space-between"}>
            <NormalText color={"#A3A3A3"} fontSize={"12px"}>
              {category}
            </NormalText>
            <NormalText color={"#010101"} fontSize={"15px"} fontWeight={"bold"}>
              {name}
            </NormalText>
            {additional &&
              additional.length > 0 &&
              additional.map((addon, index) => (
                <NormalText
                  key={"addon-" + index}
                  color={"#010101"}
                  fontSize={"12px"}
                >
                  + {addon.name}
                </NormalText>
              ))}
          </Stack>
        </Stack>
        <Stack direction={"column"} justifyContent={"space-between"}>
          <NormalText color={"black"} fontSize={"15px"} textAlign={"right"}>
            {formatCurrency(Number((price + totalPriceAdditional) * qty))}
          </NormalText>
          <CounterButton
            buttonWidth="20px"
            value={qty}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
          />
        </Stack>
      </Stack>
    </Container>
  );
};


const Container = styled.div`
  width: 100%;
  position: relative;
  min-height: 70px;
  background: white;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
`;

const XButton = styled.img`
  user-select: none;
  -webkit-user-drag: none;
  position: absolute;
  right: -7px;
  top: -7px;
  cursor: pointer;
`;

export default CartItem;
