import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategoryState } from './types';
import { getToken } from 'helper/general';
import axios from 'axios';

export const getCategoryList = createAsyncThunk('product/list', async () => {
  const config = {
    headers: {
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/pos/category-list`,
      config
    );
    if (response && response.data) {
      return {
        data: response.data,
        status: 'success',
        error: null,
      } as unknown as CategoryState;
    } else {
      return {
        data: response.data?.message,
        status: 'empty',
        error: null,
      } as unknown as CategoryState;
    }
  } catch (err) {
    if (err)
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as CategoryState;
  }
});

export const categoryList = createAsyncThunk('category/list', async () => {
  const config = {
    headers: {
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/pos/category/list`,
      config
    );
    if (response && response.data) {
      return {
        data: response.data,
        status: 'success',
        error: null,
      } as unknown as CategoryState;
    } else {
      return {
        data: response.data?.message,
        status: 'empty',
        error: null,
      } as unknown as CategoryState;
    }
  } catch (err) {
    if (err)
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as CategoryState;
  }
});
