import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProfileState, ProfileProps, UpdatePasswordProps } from './types';
import axios from 'axios';

export const postProfile = createAsyncThunk(
  'profile/updateProfile',
  async (props: ProfileProps) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/merchant/update/profile?device=MINIPOS`,
        {
          email: props.email,
          first_name: props.first_name,
          last_name: props.last_name,
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as ProfileState;
    }
  },
);

export const getUpdateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (props: ProfileProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/users/session/profile`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as ProfileState;
    }
  },
);

export const updatePatchPassword = createAsyncThunk(
  'profile/updatePassword',
  async (props: UpdatePasswordProps) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/merchant/users/update-password`,
        {
          old_password: props.old_password,
          new_password: props.new_password,
          confirm_password: props.confirm_password,
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as ProfileState;
    }
  },
);
