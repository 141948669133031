import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DashboardState,
  DashboardProps,
  TotalCashState,
  TotalCashProps,
} from './types';
import axios from 'axios';

export const getDashboardInfo = createAsyncThunk(
  'pos/dashboard-info',
  async (props: DashboardProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/${props.store_id}/get-order-information`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as DashboardState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as DashboardState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as DashboardState;
    }
  },
);

export const getTotalCashDashboard = createAsyncThunk(
  'pos/total-cash',
  async (props: TotalCashProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/${props.store_id}/get-total-cash`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as TotalCashState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as TotalCashState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as TotalCashState;
    }
  },
);
