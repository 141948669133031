import { createAsyncThunk } from '@reduxjs/toolkit';
import { KonfirmOrderProps, KonfirmOrderState } from './types';
import axios from 'axios';

export const confirmOrder = createAsyncThunk(
  'confirmOrder/konfirm-order',
  async (props: KonfirmOrderProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/${props.order_number}/print-invoice-paid?reference_number=${props.ref}`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as KonfirmOrderState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as KonfirmOrderState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as KonfirmOrderState;
    }
  },
);
