import React from "react";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import { formatCurrency } from "helper/general";
import { Box } from "@mui/material";
import NoPhoto from "assets/images/no-product-photo.svg";


interface IOrderItem {
  price?: any;
  image?: string;
  nameProduct?: string;
  additionalMenu?: any;
  qty?: number;
  useLine?: boolean;
}

const OrderItem = ({
  image,
  nameProduct,
  additionalMenu,
  qty,
  price,
  useLine,
}: IOrderItem) => {

  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <ProductBox>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div
          style={{
            width: "60%",
            flexDirection: "row",
            display: "flex",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              width: "20%",
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {imageError ? (
              <img
                src={ NoPhoto}
                alt={"img"}
                style={{ width: "40px", height: "40px", borderRadius: '10px' }}
              />
            ) : (
              <img
                src={ image }
                alt={"img"}
                style={{ width: "40px", height: "40px", borderRadius: '10px' }}
                onError={handleImageError}
              />
            )}
          </div>
          <div
            style={{
              width: "80%",
              alignItems: "center",
            }}
          >
            <NormalText
              textAlign={"left"}
              fontWeight={"bold"}
              fontSize={"15px"}
              lineHeight={"16px"}
              margin={"0px 0px 5px 13px"}
            >
              {nameProduct ?? "Undefined"}
            </NormalText>
            <Box>
              {additionalMenu && additionalMenu.length !== 0
                ? additionalMenu.map((item: any) => (
                    <div>
                      <NormalText
                        textAlign={"left"}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        margin={"0px 0px 5px 13px"}
                      >
                        {item.product_name}
                      </NormalText>
                    </div>
                  ))
                : null}
            </Box>
          </div>
        </div>
        <div
          style={{
            width: "10%",
          }}
        >
          <NormalText
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={"15px"}
            lineHeight={"16px"}
            margin={"10px 0px 5px 0px"}
          >
            {qty ?? "1"}
          </NormalText>
        </div>
        <NormalText
          textAlign={"center"}
          fontWeight={"bold"}
          fontSize={"15px"}
          lineHeight={"16px"}
          margin={"10px 0px 5px 25px"}
        >
          {formatCurrency(price ?? 0)}
        </NormalText>
      </div>
      {useLine && <Line />}
    </ProductBox>
  );
};

const ProductBox = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;

  @media only screen and (max-width: 450px) {
    min-height: unset;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 0px;
  margin-bottom: 10px;
  border: 1px solid #f1f1f1;
`;

export default OrderItem;
