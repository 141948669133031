import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "components/styled/text.styled";
import { Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <Box width={"100%"}>
        <Stack direction={"column"} margin={"100px 0 0 0"}>
          <NormalText fontWeight="bold" textAlign={"center"} fontSize="30px">
            Oops.
          </NormalText>
          <NormalText fontWeight="bold" textAlign={"center"}>
            Halaman tidak ditemukan
          </NormalText>
          <NormalText
            textAlign={"center"}
            fontSize={"12px"}
            isPressable
            onClick={() => navigate("/")}
            textDecoration={"underline"}
            color={"grey"}
          >
            Kembali ke beranda
          </NormalText>
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default NotFoundPage;
