import React from "react";
import { Box, Stack } from "@mui/material";
import VirtualAccountImage from "assets/images/va_image.svg";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import CopyIcon from "assets/icons/ic_copy.svg";
import { notify } from "helper/general";
import useRedux from "redux/useRedux";
import { getOrderInvoice } from "redux/checkout";
import { checkOrderStatus } from "redux/payment/payment.services";
import { useNavigate } from "react-router-dom";
// import "./paymentView.css";
import BackButton from "components/Button/back.button";

export interface IMetadataPayment {
  payment_string: string;
  payment_method: string;
}

interface IPaymentView {
  show: boolean;
  metadata: IMetadataPayment;
}

const PaymentView = ({ show, metadata }: IPaymentView) => {
  const {
    thunkDispatch,
    storeState: { Invoice },
  } = useRedux();
  const [paid, setPaid] = React.useState(false);
  const navigate = useNavigate();

//   const downloadQR = () => {
//     var canvas = document.querySelector(
//       "#qrcode > canvas"
//     ) as HTMLCanvasElement;
//     console.log("canvas", canvas);
//     const pngUrl = canvas
//       .toDataURL("image/png")
//       .replace("image/png", "image/octet-stream");
//     let downloadLink = document.createElement("a");
//     downloadLink.href = pngUrl;
//     downloadLink.download = `QR-${
//       Invoice &&
//       Invoice.data &&
//       Invoice.data.data &&
//       Invoice.data.data.order_number
//         ? Invoice.data.data.order_number
//         : "Code"
//     }.png`;
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//   };

  const checkUserPayment = React.useCallback(() => {
    if (show)
      setTimeout(() => {
        if (
          Invoice &&
          Invoice.data &&
          Invoice.data.data &&
          Invoice.data.data.order_number
        ) {
          console.log(Invoice.data.data.order_number);
          if (show && !paid) {
            checkOrderStatus(Invoice.data.data.order_number)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.data.status === "PAID") {
                    setPaid(true);
                    notify("success", "Pembayaran terkonfirmasi");
                    thunkDispatch(
                      getOrderInvoice({
                        order_number: res.data.data.order_number,
                      })
                    )
                      .unwrap()
                      .then(() => {
                        setTimeout(() => {
                          navigate("/order");
                        }, 5000);
                      })
                      .catch();
                  } else {
                    checkUserPayment();
                  }
                }
              })
              .catch((e) => {
                console.log("Got Error: PYMNTCK", e);
              });
          }
        }
      }, 5000);
  }, []); //eslint-disable-line

  React.useEffect(() => {
    if (show) {
      checkUserPayment();
    }
  }, [checkUserPayment, show]); //eslint-disable-line

  return (
    <>
      {show ? (
        <Box
          width={"100%"}
          height={"100%"}
          textAlign={"center"}
          sx={{ overflowX: "none", position: "relative", overflowY: "auto" }}
          className={"payment-container"}
        >
          <Box sx={{ position: "absolute", top: "50px", left: "10px" }}>
            <BackButton prev_url="/" />
          </Box>

          <NormalText
            style={{ textTransform: "uppercase" }}
            fontSize={"16px"}
            fontWeight={"bold"}
            textAlign={"center"}
            margin={"50px auto 10px auto"}
            className={"title"}
            maxWidth="80%"
          >
            pembayaran dengan{" "}
            {metadata.payment_method === "virtual_account"
              ? "Virtual Account"
              : "QRIS"}
          </NormalText>

          {/* {metadata.payment_method === "qr_code" ? (
            <Stack width={"100%"} direction={"row"} justifyContent={"center"}>
              <img src={QRISLogo} alt="" width={"144px"} />
            </Stack>
          ) : null} */}

          <Box
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
            margin={"2% 0 0 0"}
          >
            <Box width={"80%"}>
              <NormalText
                fontFamily="Roboto"
                fontSize={"12px"}
                color={"grey"}
                textAlign={"center"}
              >
                {metadata.payment_method === "virtual_account"
                  ? "Masukkan kode virtual account yang anda dapatkan untuk melakukan pembayaran transaksi menggunakan m-banking atau atm terdekat."
                  : "Untuk melanjutkan pembayaran, silahkan unduh QRIS dibawah ini dan baca petunjuk dibawah."}
              </NormalText>
            </Box>
          </Box>
          <Box
            textAlign={"center"}
            margin={
              metadata.payment_method === "virtual_account"
                ? "100px 0 40px 0"
                : "0px 0 5px 0"
            }
            className={"info-box"}
          >
            {metadata.payment_method === "virtual_account" ? (
              <img
                src={VirtualAccountImage}
                style={{ width: "300px" }}
                alt={"va_string"}
                className={"illustration"}
              />
            ) : null }
          </Box>
          {metadata.payment_method === "virtual_account" && (
            <Box display={"flex"} justifyContent={"center"}>
              <AccountContainer>
                <Stack direction={"row"} id={"virtual_account"}>
                  {metadata.payment_string}
                  <CopyBox
                    src={CopyIcon}
                    alt={"ic_copy"}
                    onClick={(e) => {
                      if (navigator && navigator.clipboard) {
                        console.log("ASDASDASD");
                        navigator.clipboard
                          .writeText(metadata.payment_string ?? "AVSCCCC")
                          .then(() => {
                            notify(
                              "success",
                              "Berhasil menyalin virtual account"
                            );
                          })
                          .catch(() =>
                            notify("error", "Gagal menyalin virtual account")
                          );
                      }
                    }}
                  />
                </Stack>
              </AccountContainer>
            </Box>
          )}

          {metadata.payment_method === "virtual_account" ? (
            <Box
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                width={"80%"}
                sx={{ wordBreak: "break-word", overflow: "hidden" }}
              >
                <NormalText
                  fontFamily="Roboto"
                  fontSize={"12px"}
                  color={"grey"}
                  textAlign={"center"}
                  margin={"10px 0"}
                >
                  Gunakan nomor virtual account diatas untuk melanjutkan
                  transaksi anda.
                </NormalText>
              </Box>
            </Box>
          ) : null}

        </Box>
      ) : null}
    </>
  );
};

const AccountContainer = styled.div`
  background: #e5e5e5;
  border-radius: 15px;
  min-width: 200px;
  font-weight: bold;
  font-size: 20px;
  color: black;
  padding: 10px 20px;
  letter-spacing: 2px;
`;

const CopyBox = styled.img`
  width: 30px;
  background: #4b9fd8;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0 0 10px;
`;

export default PaymentView;
