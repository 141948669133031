import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';
import { NormalText } from 'components/styled/text.styled';
import { ContentContainer } from 'components/styled/layout.styled';
import InputField from 'components/Form/InputField';
import { Button } from 'components/styled/button.styled';
import BottomSheet from 'components/BottomSheet';
import { useNavigate } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { updatePatchPassword } from 'redux/profile';
import { AuthLogout } from 'redux/auth';
import { notify, removeAllState } from 'helper/general';
import { UbahPassword, KembaliIcon } from 'assets/icons';

const UpdatePasswordPage = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [payload, setPayload] = React.useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });
  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  const handleReset = () => {
    setSubmit(true);
    if (
      payload.new_password !== payload.old_password &&
      payload.new_password &&
      payload.confirm_password &&
      payload.new_password.length >= 8 &&
      payload.confirm_password.length >= 8 &&
      payload.new_password === payload.confirm_password
    ) {
      setLoading(true);
      thunkDispatch(
        updatePatchPassword({
          token: Auth?.data?.access_token ?? '',
          old_password: payload.old_password,
          confirm_password: payload.confirm_password,
          new_password: payload.new_password,
        }),
      )
        .unwrap()
        .then((res: any) => {
          if (res && res.status === 'success') {
            notify('success', 'Update Password Berhasil');
            setSuccess(true);
            handleLogout();
          } else if (res && res.status === 'error') {
            if (res.error.response) {
              notify('error', res.error.response.data.message);
            }
          }
        })
        .catch((err: any) => console.log('catch error', err.mesage))
        .finally(() => setLoading(false));
    }
  };

  const handleLogout = () => {
    thunkDispatch(AuthLogout())
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          removeAllState();
          notify('success', 'Kamu telah berhasil keluar dari aplikasi!');
        } else if (res && res.status === 'error') {
          if (res.error.response) {
            notify('error', res.error.response.data.message);
          }
        }
      })
      .catch((err) => notify('error', err.message));
  };

  return (
    <MainLayout background="#FFC535" hasNavbar activePage="/profile">
      <Box width={'100%'}>
        <StyledBox>
          <div style={{ flexDirection: 'row' }}>
            <div onClick={() => navigate('/profile')}>
              <img
                src={KembaliIcon}
                alt={'Sandbox Logo'}
                style={{ marginLeft: '20px' }}
              />
            </div>
            <StyledContainter>
              <img src={UbahPassword} alt={'Sandbox Logo'} />
            </StyledContainter>
          </div>
        </StyledBox>
        <ContentContainer height="80vh" paddingBottom="490px">
          <NormalText
            textAlign={'left'}
            fontSize={'20px'}
            fontWeight={'bold'}
            lineHeight={'24px'}
            margin={'30px 0 30px 0'}
          >
            Update Password
          </NormalText>
          <Stack direction={'column'} spacing={2}>
            <InputField
              inputIcon={'LockIcon'}
              label={'Password Lama'}
              required
              type={'password'}
              onChange={(e: any) =>
                setPayload({ ...payload, old_password: e.target.value })
              }
              placeholder={'Masukan Password Lama Anda'}
              validationText={
                submit && !payload.old_password
                  ? 'Silahkan masukan password lama anda'
                  : submit && payload.old_password === payload.new_password
                  ? 'Password lama tidak boleh sama dengan password baru'
                  : submit && payload.old_password.length < 8
                  ? 'Password lama tidak boleh kurang dari 8 karakter'
                  : ''
              }
              validationType={
                (submit && !payload.old_password) ||
                (submit && payload.old_password === payload.new_password) ||
                (submit && payload.old_password.length < 8)
                  ? 'error'
                  : undefined
              }
            />
            <InputField
              inputIcon={'LockIcon'}
              label={'Password Baru'}
              required
              type={'password'}
              onChange={(e: any) =>
                setPayload({ ...payload, new_password: e.target.value })
              }
              placeholder={'Masukan Password Baru Anda'}
              validationText={
                submit && !payload.new_password
                  ? 'Silahkan masukan password baru anda'
                  : submit && payload.confirm_password !== payload.new_password
                  ? 'password baru harus sama dengan konfirmasi password'
                  : submit && payload.new_password.length < 8
                  ? 'Password baru tidak boleh kurang dari 8 karakter'
                  : ''
              }
              validationType={
                (submit && !payload.new_password) ||
                (submit && payload.confirm_password !== payload.new_password) ||
                (submit && payload.new_password.length < 8)
                  ? 'error'
                  : undefined
              }
            />
            <InputField
              inputIcon={'LockIcon'}
              label="Verifikasi Password Baru"
              required
              onChange={(e: any) =>
                setPayload({ ...payload, confirm_password: e.target.value })
              }
              type={'password'}
              placeholder="Verifikasi Password Baru Anda"
              validationText={
                submit && !payload.confirm_password
                  ? 'Silahkan masukan verifikasi password anda'
                  : submit && payload.confirm_password !== payload.new_password
                  ? 'password baru harus sama dengan konfirmasi password'
                  : submit && payload.confirm_password.length < 8
                  ? 'Konfirmasi Password tidak boleh kurang dari 8 karakter'
                  : ''
              }
              validationType={
                (submit && !payload.confirm_password) ||
                (submit && payload.confirm_password !== payload.new_password) ||
                (submit && payload.confirm_password.length < 8)
                  ? 'error'
                  : undefined
              }
            />
          </Stack>
          <Button
            width={'100%'}
            variant={loading ? 'secondary' : 'primary'}
            margin="40px 0 0 0"
            onClick={() => (loading ? null : handleReset())}
          >
            {loading ? 'Tunggu Sebentar' : 'Update Password'}
          </Button>
        </ContentContainer>
        {success && <InformationSheet onConfirm={() => navigate('/profile')} />}
      </Box>
    </MainLayout>
  );
};

const InformationSheet = ({ onConfirm }: { onConfirm: any }) => {
  return (
    <BottomSheet height="185px">
      <Box padding={'40px'}>
        <NormalText fontSize="16px" margin="0 0 25px 0">
          Password anda telah di perbaharui
        </NormalText>
        <Button variant="primary" onClick={onConfirm}>
          OK
        </Button>
      </Box>
    </BottomSheet>
  );
};

const StyledBox = styled(Box)`
  height: 185px;
  position: relative;
  margin-top: 30px;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default UpdatePasswordPage;
