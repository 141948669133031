import { AuthState, AuthData } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { AuthLogin, AuthLogout } from './auth.services';
import { removeAllState } from 'helper/general';

export const initialState: AuthState = {
    data: {} as AuthData,
    status: "loading",
    error: {}
}

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(AuthLogin.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(AuthLogin.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(AuthLogin.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(AuthLogout.fulfilled, (state, action) => {
                removeAllState().then(() => {
                    window.location.href = '/login'
                })
            })
    }
})

export const AuthReducer = AuthSlice.reducer