import styled from 'styled-components';
import AddImage from 'assets/icons/ic-add-image.svg';
import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  main: boolean;
  file: any;
  onChange: any;
  loading?: boolean;
  handleDeleteImage?: any;
  keyTarget?: any;
}

const UploadProductPhotoItem = ({
  main,
  file,
  onChange,
  loading,
  handleDeleteImage,
  keyTarget,
}: Props) => {
  return (
    <Wrapper>
      {file ? (
        <a
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            display: 'block',
            cursor: 'pointer',
            padding: '5px',
            background: '#ffffff',
            borderRadius: '50%',
            border: '2px dashed #808080',
            zIndex: '10000',
          }}
          onClick={() => {
            handleDeleteImage(keyTarget);
          }}
        >
          <DeleteIcon />
        </a>
      ) : null}

      <Button>
        {file ? (
          <img
            style={{ width: '100px', height: '100px' }}
            src={file}
            alt={''}
          />
        ) : loading ? (
          <CircularProgress size={15} />
        ) : (
          <img
            alt={''}
            style={{ width: '30px', height: '30px' }}
            src={AddImage}
          />
        )}
        <StyledInput
          type={'file'}
          onChange={onChange}
          accept={'image/*'}
        ></StyledInput>
        {main && (
          <TextWrapper>
            <Text>Foto Utama</Text>
          </TextWrapper>
        )}
      </Button>
    </Wrapper>
  );
};

const Button = styled.button`
  color: grey;
  background: white;
  font-size: 20px;
  height: 120px;
  width: 120px;
  margin: 0 10px 0px 0;
  border: 2px dashed grey;
  border-radius: 3px;
  align-items: center;
  flex-shrink: 0;
`;

const StyledInput = styled.input`
  font-size: 120px;
  position: absolute;
  height: 120px;
  width: 120px;
  top: 10px;
  left: 10px;
  z-index: 10;
  right: 10px;
  opacity: 0;
  border-radius: 3px;
`;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  flex-shrink: 0;
`;

const TextWrapper = styled.div`
  background: #f1f1f1;
  position: absolute;
  bottom: 0px;
  opacity: 0.8;
  left: 0px;
  z-index: 9;
  width: 120px;
  align-items: center;
  border-radius: 0 0 3px 3px;
`;

const Text = styled.p`
  font-size: 10px;
  color: black;
  margin: 10px;
  opacity: 1;
  font-weight: bold;
`;

export default UploadProductPhotoItem;
