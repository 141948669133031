import useRedux from 'redux/useRedux';

const CheckPermission = (
  feature_name: string,
  permission: 'is_view' | 'is_edit' | 'is_add' | 'is_delete'
) => {
  const {
    storeState: { Auth },
  } = useRedux();
  if (Auth && Auth.status === 'success') {
    if (Auth.data && Auth.data && Auth.data.features_and_permissions) {
      let check = Auth.data.features_and_permissions.some(
        (f) =>
          f.feature_name === feature_name && eval(`f.${permission}`) === true
      );

      return check;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default CheckPermission;
