import { DashboardState, DashboardResponseProps } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { getDashboardInfo } from './dashboard.services';

export const initialState: DashboardState = {
  data: {} as DashboardResponseProps,
  status: 'loading',
  error: {},
};

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getDashboardInfo.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      });
  },
});

export const DashboardReducer = DashboardSlice.reducer;
