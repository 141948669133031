import { BrandState, BrandResponseProps } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { brandList } from './brand.services';

export const initialState: BrandState = {
  data: {} as BrandResponseProps,
  status: 'loading',
  error: {},
};

export const BrandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(brandList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(brandList.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(brandList.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      });
  },
});

export const BrandReducer = BrandSlice.reducer;
