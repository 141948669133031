import React from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { MinusIcon, PlusIcon } from "assets/icons";

export interface CounterProps {
  onIncrease?: React.MouseEventHandler<HTMLButtonElement>;
  onDecrease?: React.MouseEventHandler<HTMLButtonElement>;
  value: string | number | any;
  background?: string;
  buttonWidth?: string;
  color?: string;
}

interface IconProps {
  buttonWidth?: string;
}

const CounterButton = ({
  onIncrease,
  onDecrease,
  value,
  buttonWidth,
  color,
}: CounterProps) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <span onClick={onDecrease}>
        <Icon src={MinusIcon} alt={"minus_ic"} buttonWidth={buttonWidth} />
      </span>
      <InputCount value={value} readOnly style={{ color: color ?? "black" }} />
      <span onClick={onIncrease}>
        <Icon src={PlusIcon} alt={"plus_ic"} buttonWidth={buttonWidth} />
      </span>
    </Stack>
  );
};

const Icon = styled.img<IconProps>`
  background: #ffc535;
  width: ${(props) => props.buttonWidth ?? "25px"};
  height: ${(props) => props.buttonWidth ?? "25px"};
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;

  :hover {
    opacity: 0.8px;
  }
`;

interface ButtonActionProps {
  background?: string;
}

export const ButtonAction = styled.button<ButtonActionProps>`
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 25px;
  text-align: center;
  font-family: "D-DIN";
  border: none;
  background: #ffc535;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const InputCount = styled.input`
  width: 20px;
  margin: 0 3px;
  border: none;
  color: black;
  font-weight: bold;
  text-align: center;
  font-family: "D-DIN";
  font-weight: 700;
  font-size: 16px;
  background: transparent;

  :focus {
    border: unset;
    outline: none;
  }

  :active {
    border: unset;
  }
`;

export default CounterButton;
