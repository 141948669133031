import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthState, AuthLoginProps, AuthData } from "./types";
import axios from "axios";
import { getToken } from "helper/general";

export const AuthLogin = createAsyncThunk('auth/login', async (props: AuthLoginProps) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/merchants/session/login`, {
            email: props.email,
            password: props.password,
            device_model: 'WEB',
            device_type: 'MINIPOS',
            device_id: 1
        }, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY ?? ''
            }
        }
        )

        if (response && response.data) {
            return {
                data: {
                    ...response.data?.data,
                    isAuthenticated: true
                } as AuthData,
                status: "success",
                error: null
            } as AuthState;
        } else {
            return {
                data: 'Wrong email or password',
                status: "error",
                error: null
            } as unknown as AuthState;

        }
    } catch (err) {
        return {
            data: 'Something went wrong!',
            status: "error",
            error: err
        } as unknown as AuthState;

    }
})

export const AuthLogout = createAsyncThunk('auth/logout', async () => {
    try {
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/merchants/session/logout`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY ?? '',
                token: getToken()
            }
        })

        if (request && request.status === 200) {
            return {
                data: 'Logged out!',
                status: "success",
                error: null
            } as unknown as AuthState;
        } else {
            return {
                data: 'Something went wrong!',
                status: "error",
                error: request
            } as unknown as AuthState;
        }
    } catch (err) {
        return {
            data: 'Something went wrong!',
            status: "error",
            error: err
        } as unknown as AuthState;
    }
})

export const AuthForgotPassword = createAsyncThunk('auth/forgot-password', async (email: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/merchants/session/forgot_password`, {
            email: email,
            is_cashier: true
        }, {
            headers: {
                api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e'
            }
        }
        )

        if (response && response.status === 200) {
            return {
                data: {
                    vaidEmail: true
                },
                status: "success",
                error: null
            } as unknown as AuthState;
        }
    } catch (err) {
        return {
            data: 'Something went wrong!',
            status: "error",
            error: err
        } as unknown as AuthState;

    }
})

export const ResetPassword = createAsyncThunk('auth/reset-password', async ({ key, new_password, confirm_password }: { key: string, new_password: string, confirm_password: string }) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/merchants/session/reset_password`, {
            key: key,
            password: new_password,
            confirm_password: confirm_password
        }, {
            headers: {
                api_key: `${process.env.REACT_APP_API_KEY}`
            }
        }
        )

        if (response && response.status === 200) {
            return {
                data: {
                    vaidEmail: true
                },
                status: "success",
                error: null
            } as unknown as AuthState;
        }
    } catch (err) {
        return {
            data: 'Something went wrong!',
            status: "error",
            error: err
        } as unknown as AuthState;

    }
})