import React from "react";
import { Box, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "components/styled/text.styled";
import { Button } from "components/styled/button.styled";

interface Props {
  open: boolean;
  handleClose: () => void;
  description?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  handleConfirm: () => void;
  hasCancel?: boolean;
  noAction?: boolean;
  icon?: string;
  useCustomButton?: React.ReactNode;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

const ConfirmationModal = ({
  open,
  handleClose,
  title,
  description,
  confirmText,
  cancelText,
  titleStyle,
  descriptionStyle,
  handleConfirm,
  hasCancel = true,
  noAction = false,
  useCustomButton,
  icon,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {icon && <img src={icon} alt={"confirmation_ic"} />}
        <NormalText
          style={{ fontWeight: "bold", fontSize: "15px", ...titleStyle }}
        >
          {title ?? "Konfirmasi"}
        </NormalText>
        {description && (
          <NormalText style={{ ...descriptionStyle, wordBreak: "break-word" }}>
            {description}
          </NormalText>
        )}
        {!noAction &&
          (useCustomButton ? (
            useCustomButton
          ) : (
            <Stack
              direction={"row"}
              spacing={1}
              margin={"10px 0 0 0"}
              justifyContent={"center"}
            >
              <Button
                variant="primary"
                onClick={handleConfirm}
                style={{ fontSize: "16px" }}
              >
                {confirmText ?? "Ya"}
              </Button>
              {hasCancel && (
                <Button variant="outline" onClick={handleClose}>
                  {cancelText ?? "Tidak"}
                </Button>
              )}
            </Stack>
          ))}
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
