import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { SearchIcon } from 'assets/icons';

interface IInputSearch {
  placeholder?: string;
  value?: string;
  onChange?: any;
  border?: any;
}

const InputSearch = ({
  placeholder,
  value,
  onChange,
}: IInputSearch) => {
  return (
    <Box>
      <Icon>
        <img src={SearchIcon} alt={'search_icon'} />
      </Icon>
      <Search placeholder={placeholder} value={value} onChange={onChange} />
    </Box>
  );
};

const Icon = styled.i`
  position: absolute;
  margin: 10px 10px 10px 16px;
`;

const Search = styled.input`
  width: 100%;
  border: none;
  padding: 0 10px 0 56px;
  border: 1px solid #bababa;
  border-radius: 5px;
  height: 45px;
  font-family: 'D-DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  letter-spacing: 0.5px;

  :focus,
  :hover {
    border-color: black;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #bababa;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bababa;
  }

  ::placeholder {
    color: #bababa;
  }
`;

export default InputSearch;
