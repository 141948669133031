import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainLayout from 'components/Layouts/MainLayout';
import { NormalText } from 'components/styled/text.styled';
import { Box, Stack } from '@mui/material';
import CheckedImg from 'assets/icons/checked.png';

const ActivatedPage = () => {
  const { status } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (status) {
      console.log('Status', status);
    }
  }, [status]);

  return (
    <MainLayout>
      <Box
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        height={'100vh'}
        textAlign={'center'}
        display={'flex'}
        sx={{ background: '#fff' }}
      >
        <Stack
          direction={'column'}
          display={'relative'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img src={CheckedImg} alt={'Sandbox Logo'} />
          <NormalText fontWeight="bold" fontSize="20px" margin="0 0 10px 0">
            Yeay, Akun kamu sudah aktif
          </NormalText>
          <div onClick={() => navigate('/login')}>
            <NormalText color={'#FFC535'} fontWeight="bold" fontSize={'14px'}>
              Login Sekarang
            </NormalText>
          </div>
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default ActivatedPage;
