import React from "react";
import { Skeleton, Stack, Grid } from "@mui/material";

const ProductLoader = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Loader />
      </Grid>
      <Grid item xs={6}>
        <Loader />
      </Grid>
      <Grid item xs={6}>
        <Loader />
      </Grid>
      <Grid item xs={6}>
        <Loader />
      </Grid>
      <Grid item xs={6}>
        <Loader />
      </Grid>
    </Grid>
  );
};

const Loader = () => {
  return (
    <Stack direction={"column"} spacing={1}>
      <Skeleton
        variant={"rectangular"}
        sx={{ height: "165px", width: "100%" }}
      />
      <div>
        <Skeleton variant={"text"} sx={{ height: "20px", width: "80%" }} />
        <Skeleton variant={"text"} sx={{ height: "20px", width: "100%" }} />
      </div>
    </Stack>
  );
};

export default ProductLoader;
