import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";

interface LoaderProps {
  size?: string;
  message?: string;
}

const Loader = ({ size, message }: LoaderProps) => {
  return (
    <div
      style={{
        top: "0",
        position: "absolute",
        background: "rgb(0 0 0 / 51%)",
        height: "100vh",
        width: "100%",
        zIndex: 4,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ContainerStyle size={size}>
          <LoadingStyle />
        </ContainerStyle>
        <NormalText color={"white"} fontSize={"12px"} margin={"10px 0 0 0"}>
          {message ?? "Mohon Tunggu"}
        </NormalText>
      </div>
    </div>
  );
};

export const CircularLoader = ({ size, message }: LoaderProps) => {
  return (
    <ContainerStyle size={size}>
      <LoadingStyle />
    </ContainerStyle>
  )
}

const LoadingStyle = styled.div`
  cursor: progress;
  border: 5px solid transparent;
  border-top: 5px solid orange;
  border-left: 5px solid orange;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 0.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ContainerStyle = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? size : "40px")};
  height: ${({ size }) => (size ? size : "40px")};
  font-size: 24px;
  text-transform: uppercase;
`;

export default Loader;
