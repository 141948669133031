// import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import StoreState from '../state';
import { AuthReducer } from 'redux/auth';
import { ProductReducer } from 'redux/product';
import { AppOptionReducer } from '../appOption/appoption.reducer';
import { OpenPosReducer } from '../openPos';
import { DashboardReducer } from 'redux/dashboard';
import { CartReducer } from 'redux/cart/cart.reducers';
import { ProfileReducer } from 'redux/profile';
//closing Pos
import { ClosingPosReducer } from 'redux/closingPos';
import { OrderReducer } from 'redux/order';
import { SessionReducer } from 'redux/session';
import { InvoiceReducer } from 'redux/checkout/invoice.reducers';
import { ConfirmOrderReducer } from 'redux/confirmOrder/confirmOrder.reducers';
import { NotificationReducer } from 'redux/notification/notification.reducer';
import { StoreInfoReducer } from 'redux/storeinfo';
import { BrandReducer } from 'redux/brand';
import { CategoryReducer } from 'redux/category';

const AppReducer = persistCombineReducers<StoreState>(
  {
    keyPrefix: '@',
    key: 'sandboxminipos',
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    serialize: true,
  },
  {
    Auth: AuthReducer,
    Brand: BrandReducer,
    Category: CategoryReducer,
    Product: ProductReducer,
    AppOption: AppOptionReducer,
    OpenPos: OpenPosReducer,
    Dashboard: DashboardReducer,
    Cart: CartReducer,
    Profile: ProfileReducer,
    Invoice: InvoiceReducer,
    //closing pos
    ClosingPos: ClosingPosReducer,
    Order: OrderReducer,

    Session: SessionReducer,
    ConfirmOrder: ConfirmOrderReducer,
    Notification: NotificationReducer,
    StoreInfo: StoreInfoReducer,
  }
);
export default AppReducer;
