import CircleCheckbox from 'components/Form/CircleCheckbox';
import { Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import styled from 'styled-components';

interface IPaymentItem {
  checked: boolean;
  label: string;
  onChange: any;
}

const PaymentItem = ({ checked, label, onChange }: IPaymentItem) => {
  return (
    <Container onClick={onChange}>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        justifyContent={'center'}
        padding={'10px'}
      >
        <CircleCheckbox checked={checked} onChange={onChange} />
        <NormalText fontWeight="bold" fontSize={'12px'}>
          {label}
        </NormalText>
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #bababa;
  width: 100%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

export default PaymentItem;
