import { ProfileState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { postProfile } from './profile.services';

export const initialState: ProfileState = {
    data: {},
    status: "loading",
    error: {}
}

export const ProfileSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postProfile.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(postProfile.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(postProfile.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const ProfileReducer = ProfileSlice.reducer