import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, BottomModal } from 'components/BottomSheet';
import styled from 'styled-components';
import { CloseYellowIcon } from 'assets/icons';
import { NormalText } from 'components/styled/text.styled';
import { Box } from '@mui/material';
import { Button } from 'components/styled/button.styled';
import useRedux from 'redux/useRedux';
import { getList, updateStatus } from 'redux/order';
import InfiniteScroll from 'react-infinite-scroll-component';
import OrderLoader from '../Order/Loader/item.loader';
import ConfirmationModal from 'components/Confirmation';
import { BillConfirmationIcon, YellowCircleCheckIcon } from 'assets/icons';
import { cancelOrder } from 'redux/checkout';

interface IPendingList {
  onClose: any;
  limit: number;
}

const PendingList = ({ limit, onClose }: IPendingList) => {
  const {
    thunkDispatch,
    storeState: { Auth, AppOption },
  } = useRedux();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [listData, setListData] = React.useState<any>([]);
  const [orderNumber, setOrderNumber] = React.useState('');
  const [remove, setRemove] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [removeSuccess, setRemoveSuccess] = React.useState(false);
  const [modalConfirmTitle, setModalConfirmTitle] = React.useState('');

  //   const [idSelectedStore, setIdSelectedStore] = React.useState<any>(0);

  React.useEffect(() => {
    getOrderPending();
  }, []); // eslint-disable-line

  const getOrderPending = () => {
    setLoading(true);
    let dataPayload = {
      store_id: AppOption.selected_store.id,
      // store_id: idSelectedStore ?? AppOption.selected_store.id,
      limit: 3,
      customer_name: '',
      order_number: '',
      filter: '',
      token: Auth?.data?.access_token ?? '',
      status: 'PENDING',
    };
    thunkDispatch(getList(dataPayload))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          console.log('sukses');
          setListData(res.data.data);
        }
      })
      .finally(() => setLoading(false));
  };

  //Change status to CLOSED
  const handleStatus = () => {
    let dataObject = {
      data: {
        order_number: orderNumber,
        status_order: 'CLOSED',
      },
      token: Auth?.data?.access_token ?? '',
    };
    thunkDispatch(updateStatus(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setRemoveSuccess(true);
          setModalConfirmTitle('Pesanan berhasil diperbarui');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = () => {
    //Change total on homepage
    thunkDispatch(cancelOrder({ order_number: orderNumber }))
      .unwrap()
      .then(async (res) => {
        if (res && res.status === 'success') {
          setRemoveSuccess(true);
          setModalConfirmTitle('Pesanan berhasil diperbarui');
          getOrderPending();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container>
      <ConfirmationModal
        open={remove}
        icon={BillConfirmationIcon}
        titleStyle={{ margin: '15px 0' }}
        title={`Apakah anda yakin ingin update status menjadi Batal ?`}
        handleClose={() => setRemove(false)}
        handleConfirm={() => {
          handleCancel();
          setRemove(false);
        }}
      />
      <ConfirmationModal
        open={complete}
        icon={BillConfirmationIcon}
        titleStyle={{ margin: '15px 0' }}
        title={`Apakah anda yakin ingin update status menjadi Selesai ?`}
        handleClose={() => setComplete(false)}
        handleConfirm={() => {
          handleStatus();
          setComplete(false);
        }}
      />
      <ConfirmationModal
        open={removeSuccess}
        icon={YellowCircleCheckIcon}
        titleStyle={{ margin: '15px 0' }}
        title={modalConfirmTitle}
        handleClose={() => {
          setRemoveSuccess(false);
          onClose();
        }}
        handleConfirm={() => null}
        noAction
      />
      <BottomModal
        style={{
          padding: 0,
          maxHeight: '310px',
          height: '300px',
        }}
      >
        <CloseButtonContainer onClick={onClose}>
          <Icon src={CloseYellowIcon} alt={'cloes_ic'} />
        </CloseButtonContainer>
        <Box padding={'0 15px'} margin={'30px 0 0 0'}>
          <NormalText
            fontWeight="bold"
            fontSize="21px"
            margin="0 0 10px 0"
            textAlign="center"
          >
            Pesanan Pending
          </NormalText>
          {loading ? (
            <OrderLoader />
          ) : (
            <>
              {listData && (
                <InfiniteScroll
                  height={'200px'}
                  dataLength={limit}
                  next={() => ''}
                  hasMore={true}
                  loader={''}
                >
                  {listData.map((item: any, i: any) => (
                    <Box justifyContent={'center'}>
                      <ListOrder>
                        <div
                          style={{ width: '80%', margin: '10px 0 10px 10px' }}
                          onClick={() => {
                            navigate(`/detail-order/${item.order_number}`);
                          }}
                        >
                          <NormalText
                            textAlign={'left'}
                            fontWeight={'bold'}
                            fontSize={'16px'}
                            lineHeight={'16px'}
                            margin={'0px 0px 5px 0px'}
                          >
                            {item.order_number}
                          </NormalText>
                          <NormalText
                            textAlign={'left'}
                            fontWeight={'bold'}
                            fontSize={'15px'}
                            lineHeight={'16px'}
                            margin={'5px 0px 0px 0px'}
                          >
                            {item.customer_name.length > 15
                              ? item.customer_name.substr(0, 16) + '...'
                              : item.customer_name}
                          </NormalText>
                        </div>
                        <div style={{ width: '20%', margin: '13px' }}>
                          <Button
                            type="submit"
                            width="70px"
                            minWidth="50px"
                            height={'32px'}
                            variant={'primary'}
                            margin="5px 0px 0x 0px"
                            onClick={() => {
                              setOrderNumber(item.order_number);
                              setRemove(true);
                            }}
                            textAlign="center"
                          >
                            Batal
                          </Button>
                        </div>
                        {/* <div style={{ width: '20%', margin: '10px' }}>
                          <Button
                            type="submit"
                            height={'32px'}
                            width={'70px'}
                            minWidth="50px"
                            variant={'primary'}
                            margin="5px 0px 0px 0px"
                            textAlign="center"
                            onClick={() => {
                              setOrderNumber(item.order_number);
                              setComplete(true);
                            }}
                          >
                            Selesai
                          </Button>
                        </div> */}
                      </ListOrder>
                    </Box>
                  ))}
                </InfiniteScroll>
              )}
            </>
          )}
        </Box>
      </BottomModal>
      {/* )} */}
    </Container>
  );
};

// const StyledStack = styled(Stack)`
//   width: 380px;
//   @media only screen and (min-width: 1024px) {
//     width: 430px;
//   }
// `;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Icon = styled.img`
  -webkit-user-drag: none;
  user-select: none;
`;

const ListOrder = styled.div`
  width: 100%;
  height: 60px;
  border: 1px solid #bababa;
  border-radius: 5px;
  flex-direction: row;
  display: flex;
  margin-top: 15px;
`;

export default PendingList;
