import { Grid, Stack } from '@mui/material';
import { ArrowLeftIcon } from 'assets/icons';
import { NormalText } from 'components/styled/text.styled';
import history from 'helper/history';

const Header = ({ pageName }: HeaderProps) => {
  return (
    <header
      style={{
        position: 'sticky',
        top: 0,
        width: '100%',
        backgroundColor: '#ffffff',
        zIndex: 10,
      }}
    >
      <Grid container padding={'15px 16px'}>
        <Grid item xs={1}>
          <div onClick={() => history.back()}>
            <img src={ArrowLeftIcon} alt={'arrow_left_icon'} />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <NormalText
              fontWeight="bold"
              fontSize={'20px'}
              textAlign={'center'}
              margin={'0 0 0 0'}
            >
              {pageName}
            </NormalText>
          </Stack>
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;

interface HeaderProps {
  pageName: string;
  back?: Function;
}
