import { createSlice } from "@reduxjs/toolkit";
import { StoreInfoResponseProps, StoreInfoState, storeInfo } from "./index";

export const initialState: StoreInfoState = {
  data: {} as StoreInfoResponseProps,
  status: "loading",
  error: {},
};

export const StoreInfoSlice = createSlice({
  name: "storeinfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(storeInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(storeInfo.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const StoreInfoReducer = StoreInfoSlice.reducer;
