import React from "react";
import { Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import { formatCurrency } from "helper/general";
import useRedux from "redux/useRedux";
import CounterButton from "components/Counter";
import { IChangeQuantity, changeQuantity } from "redux/cart/cart.reducers";
import ConfirmationModal from "components/Confirmation";
import { BillConfirmationIcon } from "assets/icons";
import NoPhoto from "assets/images/no-product-photo.svg"


interface IProductItem {
  productId: number;
  image: string;
  name: string;
  price: number;
  onButtonClick: any;
  onBoxClick: any;
  customize: boolean;
}

const ProductItem = ({
  image,
  name,
  price,
  customize,
  onButtonClick,
  onBoxClick,
  productId,
}: IProductItem) => {
  const {
    thunkDispatch,
    storeState: { Cart },
  } = useRedux();
  const [remove, setRemove] = React.useState(false);
  const [removeId, setRemoveId] = React.useState("");
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  
  const handleChangeQuantity = (param: IChangeQuantity) => {
    thunkDispatch(changeQuantity(param))
      .unwrap()
      .then(() => setRemove(false));
  };
  return (
    <ProductBox>
      <ConfirmationModal
        open={remove}
        icon={BillConfirmationIcon}
        titleStyle={{ margin: "15px 0" }}
        title={"Apakah anda yakin akan membatalkan item?"}
        handleClose={() => setRemove(false)}
        handleConfirm={() =>
          handleChangeQuantity({
            cart_id: removeId ?? "",
            type: "remove",
          })
        }
      />
      {imageError ? (
        <ProductImage src={NoPhoto} alt={"sample"} onClick={onBoxClick} />
      ) : (
        <ProductImage src={image} alt={"sample"} onClick={onBoxClick} onError={handleImageError} />
      )}
      <ProductInfo>
        <NormalText fontSize={"14px"} fontWeight={"bold"} title={name}>
          {name.length > 12 ? name.substr(0, 12) + "..." : name}
        </NormalText>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <NormalText fontSize={"14px"} fontWeight={"bold"}>
            {formatCurrency(price)}
          </NormalText>
          {!customize &&
          Cart &&
          Cart.items &&
          Cart.items.length > 0 &&
          Cart.items.some((x) => x.product_id === productId) ? (
            <div style={{ marginLeft: "8px" }}>
              <CounterButton
                color="white"
                value={Cart.items.find((x) => x.product_id === productId)?.qty}
                onIncrease={() =>
                  handleChangeQuantity({
                    cart_id:
                      Cart.items.find((x) => x.product_id === productId)
                        ?.cart_id ?? "",
                    type: "increase",
                  })
                }
                onDecrease={() => {
                  let idx = Cart.items.find((x) => x.product_id === productId);
                  if (idx && idx.qty === 1) {
                    setRemoveId(idx.cart_id ?? "");
                    setRemove(true);
                  } else {
                    handleChangeQuantity({
                      cart_id:
                        Cart.items.find((x) => x.product_id === productId)
                          ?.cart_id ?? "",
                      type: "decrease",
                    });
                  }
                }}
              />
            </div>
          ) : (
            <AddButton onClick={onButtonClick}>Add +</AddButton>
          )}
        </Stack>
      </ProductInfo>
    </ProductBox>
  );
};

// const Flag = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   background: rgba(0, 0, 0, 0.7);
//   font-size: 10px;
//   padding: 2px 10px;
//   color: white;
// `;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  min-height: 160px;

  @media only screen and (max-width: 768px) {
    min-height: 250px;
    height: 250px;
  }

  @media only screen and (max-width: 450px) {
    min-height: 150px;
    height: 150px;
  }
  
  @media only screen and (min-width: 1024px) {
    min-height: 170px;
    height: 170px;
  }
`;

const ProductBox = styled.div`
  width: 100%;
  position: relative;
  min-height: 160px;
  cursor: pointer;

  @media only screen and (max-width: 450px) {
    min-height: unset;
    height: 180px;
  }
`;

const ProductInfo = styled.div`
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.5);
`;

const AddButton = styled.button`
  width: 60px;
  height: 25px;
  background: #ffc535;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-family: "D-DIN";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  border: none;
`;

export default ProductItem;
