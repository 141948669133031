import { NormalText } from "components/styled/text.styled";
import React from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { Box } from "@mui/material";
import { QrisImage } from "assets/images";
import { Button } from "components/styled/button.styled";
import ConfirmationModal from "components/Confirmation";
import { BillConfirmationIcon } from "assets/icons";
import { notify } from "helper/general";
import { useNavigate } from "react-router-dom";
import { checkPaymentStatus } from "redux/payment/payment.services";
import useRedux from "redux/useRedux";
import { resetCart } from "redux/cart/cart.reducers";
import { cancelOrder } from "redux/checkout";
import { CircularLoader } from 'components/Loader'
import PrintConfirmation from "components/Confirmation/print.confirmation";

export interface IQRView {
  order_number: string;
  qr_string: string;
}

const QRView = ({ order_number, qr_string }: IQRView) => {
  const { thunkDispatch } = useRedux();
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const [success, setSuccess] = React.useState(false);
  const [openPrintOption, setOpenPrintOption] = React.useState(false);
  const [refetch, setRefetch] = React.useState(1);

  if (window && window.location && window.location.pathname === "/checkout") {
    if (window && window.history) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        setModal(true);
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }

  const handleCancel = () => {
    thunkDispatch(cancelOrder({ order_number: order_number }))
      .unwrap()
      .then(async (res) => {
        if (res && res.status === "success") {
          notify("success", res.data.message);
          await thunkDispatch(resetCart())
            .unwrap()
            .then(() => {
              navigate("/");
            });
        } else if (res && res.status === "error") {
          if (res.error.response) {
            notify("error", res.error.response.data.message);
          }
        }
      });
  };

  React.useMemo(() => {
    const orderCheck = async () => {
      if (order_number && qr_string) {
        if (error === undefined && !success) {
          await checkPaymentStatus(order_number)
            .then((res) => {
              if (res.status === 200) {
                if (res.data.data.status === "PAID") {
                  notify("success", "Pembayaran terkonfirmasi");
                  setSuccess(true);
                  thunkDispatch(resetCart());
                  setOpenPrintOption(true)
                } else {
                  setTimeout(() => {
                    setRefetch(Math.random())
                  }, 3000)
                }
              }
            })
            .catch((e) => {
              setError(e);
              console.log("Got Error: PYMNTCK", e);
            });
        } else {
          console.log("ERROR", error);
        }
      } else {
        notify("error", "Permintaan Tidak Valid");
        navigate("/");
      }
    }

    return orderCheck()

  }, [refetch]) //eslint-disable-line

  return (
    <ContainerView>
      <PrintConfirmation open={openPrintOption} />
      <ConfirmationModal
        open={modal}
        icon={BillConfirmationIcon}
        title={"Apakah anda ingin membatalkan transaksi"}
        handleClose={() => setModal(false)}
        handleConfirm={() => handleCancel()}
      />
      <NormalText
        fontFamily="D-DIN"
        fontWeight="bold"
        textAlign="center"
        fontSize="20px"
        margin="15px 0 0 0"
      >
        Transaksi QRIS
      </NormalText>
      <Box
        width={"100%"}
        justifyContent={"center"}
        sx={{ textAlign: "-webkit-center" }}
      >
        <TitleBox>
          <img src={QrisImage} alt={"ic_qris"} />
        </TitleBox>
      </Box>
      <NormalText fontWeight="bold" fontSize={"20px"} textAlign={"center"}>
        Pembayaran Digital
      </NormalText>
      <NormalText
        fontWeight="normal"
        fontSize={"15px"}
        textAlign={"center"}
        color={"#BABABA"}
        margin={"3px 0 50px 0"}
      >
        Pembayaran menggunakan Bank & e-Wallet
      </NormalText>
      <QRCode value={qr_string} level={"Q"} size={280} />
      <br />
      {!success ? (
        <Box display="flex" padding="20px 0" justifyContent="center" alignItems="center">
          <CircularLoader size="20px" />
          <NormalText margin="0 0 0 10px" fontSize="10px">Menuggu Pembayaran</NormalText>
        </Box>
      ) : (
        <Button
          width={"280px"}
          margin={"40px 0 0 0"}
          variant={"primary"}
          onClick={() => {
            notify("success", "Transaksi Selesai");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }}
        >
          Transaksi Selesai
        </Button>
      )}

      {!success && (
        <Button
          width={"280px"}
          margin={"10px 0 0 0"}
          variant={"outline"}
          onClick={() => setModal(true)}
        >
          Kembali
        </Button>
      )}
      <NormalText style={{ bottom: 0, margin: "40px 0 0 0" }} fontSize={"12px"}>
        Powered By Sandbox
      </NormalText>
    </ContainerView>
  );
};

const ContainerView = styled.div`
  width: 100%;
  height: 100vh;
  background: white;
  text-align: center;
  position: absolute;
  z-index: 1;
`;

const TitleBox = styled.div`
  margin: 20px 0;
  width: fit-content;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

export default QRView;
