import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import { useNavigate } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { ReactComponent as CheckedImage } from '../../assets/images/Icchecked.svg';
import { Button } from 'components/styled/button.styled';
import { notify } from 'helper/general';
import PrintConfirmation from 'components/Confirmation/print.confirmation';

function SuccessPage() {
  const {
    storeState: { Invoice },
  } = useRedux();

  const navigate = useNavigate();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  return (
    <MainLayout hideBackgroundImage>
      <PrintConfirmation open={openPrintModal} />
      <div className="container text-center" style={{ margin: '0 0 0 0' }}>
        <CheckedImage
          fill={'#FFC535'}
          style={{
            height: 60,
            width: 60,
            margin: '150px 0 0 0',
            userSelect: 'none',
          }}
        />
        <NormalText
          fontSize="18px"
          margin="20px"
          fontWeight="600"
          fontFamily="D-DIN EXP"
        >
          {Invoice &&
          Invoice.data &&
          Invoice.data.data &&
          Invoice.data.data.payment_status === 'PENDING'
            ? 'MENUNGGU PEMBAYARAN'
            : 'PEMBAYARAN BERHASIL'}
        </NormalText>
        {Invoice.data.data.order_number && (
          <>
            <NormalText margin={'15px 0 0 0'} fontWeight="bold">
              Nomor Order
            </NormalText>
            <NormalText>{Invoice.data.data.order_number}</NormalText>
          </>
        )}

        <NormalText
          wordWrap="break-word"
          color="#000000"
          fontSize="12px"
          fontWeight="bold"
          fontFamily="Open Sans"
          margin={'15px 0 0 0'}
        >
          {Invoice &&
          Invoice.data &&
          Invoice.data.data &&
          Invoice.data.data.store_address
            ? Invoice.data.data.store_address
            : ''}
        </NormalText>

        <Stack direction="column" marginTop={5}>
          <Button
            variant={'primary'}
            onClick={() => {
              setOpenPrintModal(true);
            }}
          >
            Cetak Struk
          </Button>

          <Button
            variant={'outline'}
            margin="5% 0 0 0"
            onClick={() => {
              notify('success', 'Transaksi Selesai');
              navigate('/');
            }}
          >
            Selesai
          </Button>
        </Stack>
      </div>
    </MainLayout>
  );
}

export default SuccessPage;
