import React from "react";
import styled from "styled-components";

interface ICircleCheckbox {
  checked?: boolean;
  onChange?: any;
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<ICircleCheckbox>`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background: ${(props) => (props.checked ? "#FFC535" : "transparent")};
  border: 1px solid ${(props) => (props.checked ? "#FFC535" : "grey")};
  border-radius: 50%;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CircleCheckbox = ({ checked, onChange }: ICircleCheckbox) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24" strokeWidth={"3px"}>
        <polyline points="18 4 9 13 4 9" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default CircleCheckbox;
