import MainLayout from 'components/Layouts/MainLayout';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import ProductManagementPhotoItem from 'components/Item/ProductManagementPhotoItem';
import CheckPermission from 'helper/checkPermission';
import { checkPermission } from 'helper/general';
import useRedux from 'redux/useRedux';
import { getStoreProducts, StoreProductShortProps } from 'redux/product';
import { useEffect, useState } from 'react';

const ProductListPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Auth, AppOption },
  } = useRedux();

  const [products, setProducts] = useState<StoreProductShortProps[]>([]);

  const initializeData = async ({ merchantId, storeId }: any) => {
    let payload = {
      merchant_id: Number(merchantId),
      store_id: Number(storeId),
    };

    try {
      const getProductList = await thunkDispatch(
        getStoreProducts(payload)
      ).unwrap();

      if (getProductList) {
        setProducts(formatResponse(getProductList.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatResponse = (data: any[]) => {
    return data.map((item) => {
      return {
        product_id: item.product_id,
        product_name: item.product_name,
        product_image: item.product_detail.product_image_main,
      };
    });
  };

  useEffect(() => {
    let payload = {
      merchantId: Auth.data.profile_data.store_access?.merchant_id,
      storeId: AppOption.selected_store.id,
    };
    initializeData(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout hideBackgroundImage hasNavbar>
      <Box width={'100%'} height={'100vh'} overflow={'hidden'}>
        <Header pageName={'Manajemen Produk'} />
        <Box
          width={'100%'}
          height={'100%'}
          padding={'15px 16px 150px 16px'}
          position={'relative'}
          overflow={'scroll'}
        >
          {products && products.length > 0
            ? products.map((product, index) => {
                return (
                  <ProductManagementPhotoItem
                    key={product + '-' + index}
                    productName={product?.product_name}
                    productImage={product?.product_image}
                    className="mb-3"
                    onClick={() =>
                      checkPermission('Product', 'is_edit')
                        ? navigate(`/product/${product.product_id}/edit`)
                        : null
                    }
                  />
                );
              })
            : null}

          {/* <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          /> */}
          {/* <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          /> */}

          {/* <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          /> */}
          {/* <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          />
          <ProductManagementPhotoItem
            className="mb-3"
            onClick={() =>
              checkPermission('Product', 'is_edit')
                ? navigate('/product/3/edit')
                : null
            }
          /> */}
        </Box>
        {CheckPermission('Product', 'is_add') ? (
          <IconButton
            sx={{
              width: '55px',
              height: '55px',
              backgroundColor: '#ffc535',
              position: 'absolute',
              bottom: '20%',
              right: '5%',
            }}
            onClick={() => navigate('/product/add')}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </MainLayout>
  );
};

export default ProductListPage;
