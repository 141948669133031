import { SessionState, SessionResponseProps } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { getSession } from './session.services';

export const initialState: SessionState = {
  data: {} as SessionResponseProps,
  status: 'loading',
  error: {},
};

export const SessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSession.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getSession.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      });
  },
});

export const SessionReducer = SessionSlice.reducer;
