import { Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import NoPhoto from 'assets/images/no-product-photo.svg';

interface ProductManagementPhotoItemProps {
  onClick: Function;
  className?: string;
  productName: string;
  productImage?: string;
}

const ProductManagementPhotoItem = ({
  onClick,
  className,
  productName,
  productImage,
}: ProductManagementPhotoItemProps) => {
  return (
    <Stack
      direction="row"
      borderRadius="8px"
      height="75px"
      overflow="hidden"
      boxShadow="0px 1px 2px 2px #dbd9d9"
      className={className}
      onClick={() => onClick()}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        height="75px"
        width="75px"
      >
        <img
          src={productImage ? productImage : NoPhoto}
          alt="Product"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </Stack>
      <Stack
        direction="column"
        sx={{ background: '#FFFFFF', height: '100%', width: '70%' }}
        justifyContent="center"
        padding={'15px 16px'}
      >
        <NormalText>{productName}</NormalText>
      </Stack>
    </Stack>
  );
};

export default ProductManagementPhotoItem;
