import { createAsyncThunk } from "@reduxjs/toolkit";
import { StoreDetailState, StoreInfoState } from "./types";
import axios from "axios";
import { getToken } from "helper/general";

export const showDetailStore = createAsyncThunk(
  "stores/show",
  async (store_id: number) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/store/${store_id}`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreDetailState;
    }
  }
);

export const storeInfo = createAsyncThunk(
  "stores/info",
  async (store_id: number) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/store/${store_id}`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreInfoState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreInfoState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreInfoState;
    }
  }
);
