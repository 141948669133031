import { createAsyncThunk } from "@reduxjs/toolkit";
import { CheckoutState, CheckoutProps, InvoiceState, CancelOrderState } from "./types";
import axios from "axios";
import { getToken } from "helper/general";

export const checkoutOrder = createAsyncThunk('checkout/create', async (props: CheckoutProps) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
            token: getToken()
        },
    };
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/order/checkout`, props, config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null,
            } as unknown as CheckoutState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null,
            } as unknown as CheckoutState;
        }
    } catch (err) {
        if (err)
            return {
                data: "Something went wrong!",
                status: "error",
                error: err,
            } as unknown as CheckoutState;
    }
})

export const getOrderInvoice = createAsyncThunk('invoice/get', async ({ order_number, reference_number }: { order_number: string, reference_number?: string }) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
            // token: getToken()
        },
    };
    try {
        // const response = await axios.get(
        //     `${process.env.REACT_APP_API_URL}/pos/${order_number}/print-invoice-paid${reference_number ? '?reference_number=' + reference_number : ''}`, config
        // );
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/merchant/order/print-invoice/${order_number}`, config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null,
            } as unknown as InvoiceState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null,
            } as unknown as InvoiceState;
        }
    } catch (err) {
        if (err)
            return {
                data: "Something went wrong!",
                status: "error",
                error: err,
            } as unknown as InvoiceState;
    }
})

export const cancelOrder = createAsyncThunk('order/cancel', async ({ order_number }: { order_number: string }) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        },
    };
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/users/order/cancel`, { order_number: order_number }, config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null,
            } as unknown as CancelOrderState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null,
            } as unknown as CancelOrderState;
        }
    } catch (err) {
        if (err)
            return {
                data: "Something went wrong!",
                status: "error",
                error: err,
            } as unknown as CancelOrderState;
    }
})