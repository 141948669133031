export const firebaseConfig = {
  apiKey: "AIzaSyDUoq04uCHWLIQ4BFDj0U2e1ZexLw0FmUM",
  authDomain: "sandbox-5cd2f.firebaseapp.com",
  projectId: "sandbox-5cd2f",
  storageBucket: "sandbox-5cd2f.appspot.com",
  messagingSenderId: "752778506275",
  appId: "1:752778506275:web:156ca989fc437dc6f20196",
  measurementId: "G-RN5JCHQ5XB",
};

export const pairKey =
  "BGBjhLTlKRrByQ1in4q1p7bls4Ou_5VNikTxTj4r7NgmbISwhDZYZSsktr8HtFSpNozDFAo0jWMCGViOm8FoGck";

export const serverKey =
  "AAAAr0UYcCM:APA91bHI3wsdRuJisUPp6lHuZEI8LkWkEyhPsI3wc9_tjnmVlv6q8N6sKcZWqRH4ZXH3aA7QNKOBWqaEsNzSBmDPmirdKMngK5sFZx_OfdAS-ILlCeusmba1SNAT_G9qs6AeKzCkTV_5";
