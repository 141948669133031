import React from 'react';
import styled from 'styled-components';
import { device } from 'components/Layouts/styles';
import {
  HomeIcon,
  TodoListIcon,
  ApprovalOrderIcon,
  UserIcon,
  PlusCircle,
} from 'assets/icons';

import { useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';
import useRedux from 'redux/useRedux';
import { updateNotification } from 'redux/notification/notification.reducer';
import { checkPermission } from 'helper/general';

interface Props {
  activePage?: string;
}

const NavigationBar = ({ activePage }: Props) => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      Notification: { notifications },
    },
  } = useRedux();
  let navigations = [
    {
      id: 'home',
      icon: HomeIcon,
      path: '/',
    },
    {
      id: 'product',
      icon: TodoListIcon,
      path: '/product',
    },
    {
      id: 'productList',
      icon: PlusCircle,
      path: '/product/list',
    },
    {
      id: 'scan',
      icon: ApprovalOrderIcon,
      path: '/order',
    },
    {
      id: 'user',
      icon: UserIcon,
      path: '/profile',
    },
  ];

  const resetNotif = () => {
    let prop: { count_notif: number; count_new_order: number } = {
      count_notif: notifications.count_notif ? 0 : 0,
      count_new_order: notifications.count_new_order
        ? notifications.count_new_order
        : 0,
    };
    thunkDispatch(updateNotification(prop));
  };

  return (
    <Container>
      <UL>
        {/* {navigations &&
          navigations.map((item) => (
            <LI
              key={item.id}
              onClick={() => {
                navigate(item.path);
                if (item.path === '/order') {
                  resetNotif();
                }
              }}
              active={
                activePage
                  ? activePage === item.path
                  : window.location.pathname === item.path
              }
            >
              {item.path === '/order' &&
              notifications.count_notif &&
              notifications.count_notif > 0 ? (
                <Badge color="error" variant="dot">
                  <Icon src={item.icon} alt={item.id} />
                </Badge>
              ) : (
                <Icon src={item.icon} alt={item.id} />
              )}
            </LI>
          ))} */}
        {navigations &&
          navigations.map((item: any) => {
            if (item.path === '/product/list') {
              if (!checkPermission('Product', 'is_add')) {
                // eslint-disable-next-line array-callback-return
                return;
              }
              return (
                <LI
                  key={item.id}
                  onClick={() => {
                    navigate(item.path);
                    if (item.path === '/order') {
                      resetNotif();
                    }
                  }}
                  active={
                    activePage
                      ? activePage === item.path
                      : window.location.pathname === item.path
                  }
                >
                  {item.path === '/order' &&
                  notifications.count_notif &&
                  notifications.count_notif > 0 ? (
                    <Badge color="error" variant="dot">
                      <Icon src={item.icon} alt={item.id} />
                    </Badge>
                  ) : (
                    <Icon src={item.icon} alt={item.id} />
                  )}
                </LI>
              );
            }
            return (
              <LI
                key={item.id}
                onClick={() => {
                  navigate(item.path);
                  if (item.path === '/order') {
                    resetNotif();
                  }
                }}
                active={
                  activePage
                    ? activePage === item.path
                    : window.location.pathname === item.path
                }
              >
                {item.path === '/order' &&
                notifications.count_notif &&
                notifications.count_notif > 0 ? (
                  <Badge color="error" variant="dot">
                    <Icon src={item.icon} alt={item.id} />
                  </Badge>
                ) : (
                  <Icon src={item.icon} alt={item.id} />
                )}
              </LI>
            );
          })}
      </UL>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: white;
  display: flex;

  @media ${device.laptop} {
    max-width: 360px;
  }
  @media ${device.laptopL} {
    max-width: 360px;
  }

  @media ${device.desktop} {
    max-width: 360px;
  }
`;

const UL = styled.ul`
  width: 100%;
  list-style-type: none;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
`;

interface ILi {
  active?: boolean;
}

const LI = styled.li<ILi>`
  transition: ease-in-out 0.3s;
  color: black;
  float: left;
  width: 48px;
  height: 48px;
  background: ${(props) => (props.active ? '#ffc535' : '')};
  border-radius: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  place-content: center;
  cursor: pointer;

  :hover {
    background: '#ffc535';
  }
`;

const Icon = styled.img`
  -webkit-user-drag: none;
  user-select: none;
`;

export default NavigationBar;
