import styled, { css } from "styled-components";
interface TextProps {
  position?: string | undefined;
  maxLines?: number | undefined;
  fontWeight?: string | undefined;
  fontFamily?: string | undefined;
  fontSize?: string | undefined;
  margin?: string | undefined;
  padding?: string | undefined;
  letterSpacing?: string | undefined;
  lineHeight?: string | undefined;
  color?: string | undefined;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  fontStyle?: string | undefined;
  textDecoration?:
    | "none"
    | "underline"
    | "overline"
    | "line-through"
    | "blink"
    | "spelling-error"
    | "grammar-error";
  opacity?: string | undefined;
  disableSelectText?: boolean;
  disableDrag?: boolean;
  isPressable?: boolean;
  maxWidth?: string;
  wordWrap?: "normal" | "break-word" | "inherit" | "initial" | undefined | null;
  textJustify?: "auto" | "inter-character" | "inter-word" | "none";
}

export const NormalText = styled.p<TextProps>`
  position: ${({ position }) => position};
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily + ", sans-serif" : "sans-serif"};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-style: ${({ fontStyle }) => fontStyle};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin ?? "0px"};
  padding: ${({ padding }) => padding ?? "0px"};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
  text-justify: ${({ textJustify }) => textJustify};
  text-decoration-line: ${({ textDecoration }) => textDecoration ?? "none"};
  opacity: ${({ opacity }) => opacity};
  word-wrap: ${({ wordWrap }) => wordWrap};
  word-break: break-word;
  ${(p) =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${(p) =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${(p) =>
    p.isPressable &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    `}
`;

export const SpanText = styled.span<TextProps>`
  position: ${({ position }) => position};
  font-family: "Ropa Sans";
  font-weight: ${({ fontWeight }) => fontWeight};
  font-style: ${({ fontStyle }) => fontStyle};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin ?? "0px"};
  padding: ${({ padding }) => padding ?? "0px"};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration-line: ${({ textDecoration }) => textDecoration ?? "none"};
  opacity: ${({ opacity }) => opacity};
  word-wrap: ${({ wordWrap }) => wordWrap};
  ${(p) =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${(p) =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${(p) =>
    p.isPressable &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    `}
`;

export const DivText = styled.div<TextProps>`
  position: ${({ position }) => position};
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily + ", sans-serif" : "sans-serif"};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-style: ${({ fontStyle }) => fontStyle};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin ?? "0px"};
  padding: ${({ padding }) => padding ?? "0px"};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
  text-justify: ${({ textJustify }) => textJustify};
  text-decoration-line: ${({ textDecoration }) => textDecoration ?? "none"};
  opacity: ${({ opacity }) => opacity};
  word-wrap: ${({ wordWrap }) => wordWrap};
  word-break: break-word;
  ${(p) =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${(p) =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${(p) =>
    p.isPressable &&
    css`
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    `}
`;
