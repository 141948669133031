import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenPosState, OpenPosProps } from './types';
import axios from 'axios';

export const postOpenPos = createAsyncThunk(
  'pos/opening',
  async (props: OpenPosProps) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pos/open-pos`,
        {
          store_id: props.store_id,
          user_merchant_id: props.user_merchant_id,
          start_total_amount: Number(props.start_total_amount),
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as OpenPosState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as OpenPosState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as OpenPosState;
    }
  },
);
