import styled, { css } from 'styled-components';

export interface AppContainerProps {
  direction?: string | undefined;
  align?: 'start' | 'end' | 'center' | 'between' | 'evenly' | undefined;
  width?: string | undefined;
  height?: string | undefined;
  wrap?: string | undefined;
  margin?: string;
  padding?: string;
  background?: string;
  position?:
  | 'fixed'
  | 'relative'
  | 'sticky'
  | 'absolute'
  | 'static'
  | '-webkit-sticky'
  | 'inherit'
  | 'initial'
  | '-ms-page'
  | 'unset'
  | undefined;
  display?: string;
  justify?: string;
  disableSelectText?: boolean;
  disableDrag?: boolean;
  isPressble?: boolean;
  zIndex?: number;
  opacity?: number;
}

export const AppContainer = styled.div<AppContainerProps>`
  position: ${({ position }) => position};
  display: ${({ display }) => display ?? 'flex'};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background};
  flex-wrap: ${({ wrap }) => wrap};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  flex-direction: ${({ direction }) => direction};
  align-items: ${p => {
    switch (p.align) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'between':
        return 'space-between';
      case 'evenly':
        return 'space-evenly';
      default:
        return undefined;
    }
  }};
  ${p =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${p =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  justify-content: ${p => {
    switch (p.align) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'between':
        return 'space-between';
      case 'evenly':
        return 'space-evenly';
      default:
        return undefined;
    }
  }};
`;

interface IContentContainer {
  height?: string;
  paddingBottom?: string;
}

export const ContentContainer = styled.div<IContentContainer>`
  background: white;
  position: relative;
  border-radius: 20px 20px 0px 0px;
  height: ${(props) => props.height ?? 'calc(100vh - 250px)'};
  width: 100%;
  padding: 20px 20px ${(props)=> props.paddingBottom ?? '450px'} 20px;
`;

interface SpacerProps {
  height?: string;
  background?: string;
}
export const SpacerLine = styled.div<SpacerProps>`
  height: ${(props) => props.height ?? '3px'};
  width: 100%;
  background: ${(props) => props.background ?? '#F2F2F2'};
`;