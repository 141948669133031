import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';
import { NormalText } from 'components/styled/text.styled';
import { ContentContainer } from 'components/styled/layout.styled';
import InputField from 'components/Form/InputField';
import { MailIcon } from 'assets/icons';
import { Button } from 'components/styled/button.styled';
import { useNavigate } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { validateEmail, notify } from 'helper/general';
import { UbahProfile, KembaliIcon } from 'assets/icons';
import { postProfile, getUpdateProfile } from 'redux/profile';
import ModalSucces from '../../components/Modal/modalSuccess';
import { AuthLogout } from 'redux/auth';
import { persistor } from 'redux/store';

const UpdateProfilePage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Auth, Profile },
  } = useRedux();
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState({
    firstName:
      Profile &&
      Profile.data &&
      Profile.data.data &&
      Profile.data.data.first_name
        ? Profile.data.data.first_name
        : Auth?.data?.profile_data?.first_name ?? '',
    lastName:
      Profile &&
      Profile.data &&
      Profile.data.data &&
      Profile.data.data.last_name
        ? Profile.data.data.last_name
        : Auth?.data?.profile_data?.last_name ?? '',
    email:
      Profile && Profile.data && Profile.data.data && Profile.data.data.email
        ? Profile.data.data.email
        : Auth?.data?.profile_data?.email ?? '',
  });
  const [submit, setSubmit] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmit(true);
    setLoading(true);
    let dataPayload = {
      token: Auth?.data?.access_token ?? '',
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
    };
    if (payload.email && validateEmail(payload.email)) {
      thunkDispatch(postProfile(dataPayload))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            notify('success', 'Update Profile Berhasil');
            setLoading(false);
            setModal(true);
            updateProfile();
          } else if (res && res.status === 'error') {
            setLoading(false);
            notify('error', res.error.response.data.message);
          }
        });
    } else {
      setLoading(false);
    }
  };

  const updateProfile = () => {
    let dataPayload = {
      token: Auth?.data?.access_token ?? '',
    };
    thunkDispatch(getUpdateProfile(dataPayload))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          console.log(res.data);
        }
      });
  };

  const handleCloseModal = () => {
    setModal(false);
    if (Auth.data && Auth.data.profile_data && Auth.data.profile_data.email) {
      if (Auth.data.profile_data.email !== payload.email) {
        handleLogout();
      }
    } else {
      handleLogout();
    }
  };

  const handleLogout = () => {
    thunkDispatch(AuthLogout())
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
        } else if (res && res.status === 'error') {
          if (res.error.response) {
            notify('error', res.error.response.data.message);
          }
        }
      })
      .catch((err) => notify('error', err.message));
  };

  return (
    <MainLayout background="#FFC535" hasNavbar activePage="/profile">
      <ModalSucces
        open={modal}
        handleClose={() => handleCloseModal()}
        textModal="Perubahan Berhasil Disimpan"
      />
      <Box width={'100%'} sx={{ overflow: 'auto' }}>
        <StyledBox>
          <div style={{ flexDirection: 'row' }}>
            <div onClick={() => navigate('/profile')}>
              <img
                src={KembaliIcon}
                alt={'Sandbox Logo'}
                style={{ marginLeft: '20px' }}
              />
            </div>
            <StyledContainter>
              <img src={UbahProfile} alt={'Sandbox Logo'} />
            </StyledContainter>
          </div>
        </StyledBox>
        <ContentContainer height="80vh" paddingBottom="500px">
          <NormalText
            textAlign={'left'}
            fontSize={'20px'}
            fontWeight={'bold'}
            lineHeight={'24px'}
            margin={'30px 0 40px 0'}
          >
            Update Profile
          </NormalText>
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ marginBottom: '100px' }}
          >
            <Stack direction={'column'} spacing={2}>
              <InputField
                inputIcon={'ProfileIcon'}
                label={'Nama Depan'}
                required
                type={'text'}
                onChange={(e: any) =>
                  setPayload({ ...payload, firstName: e.target.value })
                }
                validationText={'Silahkan masukan Nama Depan anda'}
                validationType={
                  submit && !payload.firstName ? 'error' : undefined
                }
                value={payload.firstName}
                placeholder={'Masukan Nama Depan Anda'}
              />
              <InputField
                inputIcon={'ProfileIcon'}
                label={'Nama Belakang'}
                required
                type={'text'}
                onChange={(e: any) =>
                  setPayload({ ...payload, lastName: e.target.value })
                }
                validationText={'Silahkan masukan Nama Belakang anda'}
                validationType={
                  submit && !payload.lastName ? 'error' : undefined
                }
                value={payload.lastName}
                placeholder={'Masukan Nama Belakang Anda'}
              />
              <InputField
                inputIcon={MailIcon}
                label={'Email'}
                required
                type={'email'}
                value={payload.email}
                onChange={(e: any) =>
                  setPayload({ ...payload, email: e.target.value })
                }
                validationText={'Silahkan masukan email yang valid'}
                validationType={
                  (submit && !validateEmail(payload.email)) ||
                  (submit && !payload.email)
                    ? 'error'
                    : undefined
                }
                placeholder={'Masukan Email Anda'}
              />
            </Stack>
            <Button
              type="submit"
              width={'100%'}
              variant={loading ? 'secondary' : 'primary'}
              margin="35px 0 0 0"
            >
              {loading ? 'Tunggu Sebentar' : 'Update Profile'}
            </Button>
          </form>
        </ContentContainer>
      </Box>
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 180px;
  position: relative;
  margin-top: 30px;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default UpdateProfilePage;
