import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClosingPosState, ClosingPosProps, AmountCashPosState, AmountPos } from './types';
import axios from 'axios';

export const postClosingPos = createAsyncThunk(
  'pos/closing',
  async (props: ClosingPosProps) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pos/close-pos`,
        {
          store_id: props.store_id,
          end_total_amount_cash: Number(props.end_total_amount_cash),
          notes: props.notes,
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as ClosingPosState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as ClosingPosState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as ClosingPosState;
    }
  },
);

export const getAmountCash = createAsyncThunk(
  'pos/getAmountCash',
  async (props: AmountPos) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pos/${props.store_id}/get-total-amount-cashier`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            token: props.token,
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as AmountCashPosState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as AmountCashPosState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as AmountCashPosState;
    }
  },
);
