import React from "react";
import styled from 'styled-components';
import { Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import useRedux from 'redux/useRedux';
import { formatCurrency } from 'helper/general';
import { IProduct } from 'redux/cart/cart.reducers';
import NoPhoto from "assets/images/no-product-photo.svg";


const ProductItems = () => {
  const {
    storeState: { Cart },
  } = useRedux();

  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const getSubTotal = (cart: IProduct) => {
    let total = cart.price * cart.qty;
    if (cart && cart.additionals && cart.additionals.length) {
      let addonsTotal = 0;
      cart.additionals.forEach((add) => {
        addonsTotal += add.qty * cart.qty * add.price;
      });
      total += addonsTotal;
    }
    return total;
  };
  return (
    <Table>
      <thead>
        <tr>
          <Th>Product</Th>
          <Th>Qty</Th>
          <Th>Total</Th>
        </tr>
      </thead>
      <tbody>
        {Cart &&
          Cart.items &&
          Cart.items.length > 0 &&
          Cart.items.map((cart, index) => (
            <Tr key={index}>
              <Td>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  {imageError ? (
                    <ProductImage src={ NoPhoto } alt={'gambar produk'} />
                  ) : (
                    <ProductImage src={cart.product_image.length === 0
                        ? NoPhoto
                        : cart.product_image
                      } 
                      alt={'gambar produk'} 
                      onError={handleImageError}
                    />
                  )}
                  <Stack direction={'column'}>
                    <NormalText fontSize={'15px'} fontWeight={'bold'}>
                      {cart.product_name}
                    </NormalText>
                    {cart && cart.additionals &&
                      cart.additionals.length !== 0 &&
                      cart.additionals.map((addon, key) => (
                        <NormalText fontSize={'12px'} key={key}>
                          {addon?.name ?? ''}
                        </NormalText>
                      ))}
                  </Stack>
                </Stack>
              </Td>
              <Td>{cart.qty}</Td>
              <Td>{formatCurrency(getSubTotal(cart))}</Td>
            </Tr>
          ))}
      </tbody>
    </Table>
  );
};

const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #ffee8e;
  padding: 4px 16px;
  font-family: 'D-DIN';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;

  color: #333333;

  :nth-child(1) {
    text-align: left;
  }
  :nth-child(2) {
    text-align: center;
  }
  :nth-child(3) {
    text-align: right;
  }
`;

const Tr = styled.tr`
  text-align: center;
`;
const Td = styled.td`
  padding: 4px 16px;
  :nth-child(1) {
    text-align: left;
  }
  :nth-child(2) {
    text-align: center;
    font-family: 'D-DIN';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: #010101;
  }
  :nth-child(3) {
    text-align: right;
    font-family: 'D-DIN';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: #010101;
  }
`;

export default ProductItems;
