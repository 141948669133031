import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import {
  BCALogo,
  MandiriLogo,
  BRILogo,
  SahabatSampoernaLogo,
  BNILogo,
  PermataLogo,
  CimbLogo,
  BSILogo,
  DBSLogo,
  BJBLogo,
} from "assets/images/bank_logo";
 
interface BankItemProps {
  code: string;
  name: string;
  onClick?: any;
  active?: boolean;
  background?: string;
}

export const logos = [
  {
    code: "BCA",
    logo: BCALogo,
  },
  {
    code: "MANDIRI",
    logo: MandiriLogo,
  },
  {
    code: "BRI",
    logo: BRILogo,
  },
  {
    code: "SAHABAT_SAMPOERNA",
    logo: SahabatSampoernaLogo,
  },
  {
    code: "BNI",
    logo: BNILogo,
  },
  {
    code: "PERMATA",
    logo: PermataLogo,
  },
  {
    code: "CIMB",
    logo: CimbLogo,
  },
  {
    code: "BSI",
    logo: BSILogo,
  },
  {
    code: "DBS",
    logo: DBSLogo,
  },
  {
    code: "BJB",
    logo: BJBLogo,
  },
];

const BankItem = ({
  code,
  name,
  onClick,
  active,
  background,
}: BankItemProps) => {
  return (
    <StyledBox onClick={onClick}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        margin={"0 0 10px 0"}
      >
        <BankLogo
          src={logos.find((x) => x.code === code)?.logo ?? ""}
          alt={"LOGO"}
        />
        <NormalText
          fontSize="12px"
          lineHeight="28.13px"
          color={active ? background ?? "#b92362" : "black"}
          fontWeight={active ? "bold" : ""}
        >
          {name}
        </NormalText>
      </Stack>
      <Spacer style={{background: '#c1c1c1'}}/>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 20px;
  padding: 0 10px;
  margin: 10px 0 0 0;
  align-items: center;
  cursor: pointer;
`;

const Spacer = styled.div`
  height: 1px;
  background: grey;
`;

const BankLogo = styled.img`
  width: 40px;
  max-height: 50px;
`;

export default BankItem;
