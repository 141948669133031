import axios from "axios";

export const checkOrderStatus = async (orderId: string) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/order/${orderId}/payment`, config)
    return response;
}

export const checkPaymentStatus = async (orderId: string) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/order/${orderId}/payment`, config)
    return response;
}