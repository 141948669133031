import React from 'react';
import { Box, Modal } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import CheckedImg from 'assets/icons/checked.png';

interface Props {
  open: boolean;
  handleClose: () => void;
  textModal?: string;
  description?: string;
}

const ModalSuccess = ({ open, handleClose, textModal, description }: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        top: '30%',
      }}
    >
      <Box
        textAlign={'center'}
        sx={{
          backgroundColor: 'white',
          width: '90%',
          height: '300px',
          maxWidth: '360px',
          justifyContent: 'center',
          alignContent: 'center',
          borderRadius: '20px',
          flexDirection: 'column',
          padding: 10,
        }}
      >
        <img src={CheckedImg} alt={'Sandbox Logo'} />
        <NormalText
          fontSize={'16px'}
          margin={'20px 10px 5px 10px'}
          textAlign={'center'}
          fontWeight="bold"
        >
          {textModal}
        </NormalText>
        <NormalText
          fontSize={'16px'}
          margin={'0px 10px 5px 10px'}
          textAlign={'center'}
          fontWeight="bold"
        >
          {description}
        </NormalText>
      </Box>
    </Modal>
  );
};

export default ModalSuccess;
