import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import { ReactComponent as LockIcon } from 'assets/icons/lock_ic.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profileIcon.svg';

interface IInputField {
  name?: any;
  placeholder?: string;
  value?: string;
  onChange?: any;
  inputIcon?: any;
  hasIcon?: boolean;
  required?: boolean;
  label?: string;
  labelStyle?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  validationType?: 'error' | 'warning';
  validationText?: string;
  fullBorder?: boolean;
  height?: string;
  isPrefixRupiah?: boolean;
  boldText?: boolean;
  readOnly?: boolean;
  widthBox?: string;
  boxStyle?: any;
}

const InputField = ({
  name,
  placeholder,
  value,
  onChange,
  inputIcon,
  labelStyle,
  required,
  label,
  type,
  validationType,
  validationText,
  fullBorder,
  height,
  isPrefixRupiah,
  boldText = true,
  readOnly,
  widthBox,
  boxStyle,
}: IInputField) => {
  return (
    <Box width={widthBox ?? '100%'} sx={boxStyle}>
      {label && (
        <NormalText
          fontWeight={boldText ? 'bold' : 'normal'}
          fontSize="14px"
          style={{ ...labelStyle }}
        >
          {label} {required ? '*' : ''}
        </NormalText>
      )}
      {inputIcon && (
        <Icon>
          {inputIcon === 'LockIcon' ? (
            <LockIcon fill={'#838383'} />
          ) : inputIcon === 'ProfileIcon' ? (
            <ProfileIcon fill={'#838383'} />
          ) : (
            <img src={inputIcon} alt={'Input Icon'} />
          )}
        </Icon>
      )}
      {isPrefixRupiah ? (
        <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
          <Span>Rp</Span>
          <Input
            type={type}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            hasIcon={inputIcon ? true : false}
            fullBorder={fullBorder}
            height={height}
            isPrefixRupiah={isPrefixRupiah}
            readOnly={readOnly}
          />
        </div>
      ) : (
        <Input
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          hasIcon={inputIcon ? true : false}
          fullBorder={fullBorder}
          height={height}
          readOnly={readOnly}
        />
      )}
      {validationType && (
        <NormalText color="red" fontSize="12px">
          {validationText ??
            'Note: Please set validationType prop to overrides this message'}
        </NormalText>
      )}
    </Box>
  );
};

const Icon = styled.i`
  position: absolute;
  margin: 7px 10px 10px 15px;
`;

const Span = styled.div`
  width: 10%;
  height: 41px;
  font-family: 'D-DIN';
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  border-color: #bababa;
  color: black;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px 0px 0px 8px;
  outline: none;
  font-size: 13px;
  :focus,
  :hover {
    border-color: black;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #cdcdcd;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cdcdcd;
  }
  ::placeholder {
    color: #cdcdcd;
  }
`;

const Input = styled.input<IInputField>`
  width: ${(props) => (props.isPrefixRupiah ? '90%' : '100%')};
  height: ${(props) => props.height ?? '41px'};
  font-family: 'D-DIN';
  border-style: solid;
  border-width: ${(props) =>
    props.fullBorder
      ? props.isPrefixRupiah
        ? '1px 1px 1px 0px'
        : '1px'
      : '0 0 1px 0'};
  border-color: #bababa;
  color: #8e8e8e;
  border-radius: ${(props) =>
    props.fullBorder
      ? props.isPrefixRupiah
        ? '0px 8px 8px 0px'
        : '8px'
      : '0px'};
  padding: ${(props) => (props.hasIcon ? '10px 5px 10px 40px' : '10px 5px')};
  outline: none;
  font-size: 13px;

  :focus,
  :hover {
    border-color: ${(props) => (props.readOnly ? '#bababa' : 'black')};
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #cdcdcd;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cdcdcd;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #cdcdcd;
  }
`;

export default InputField;
