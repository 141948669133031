import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateString } from 'helper/general';
import { ReduxStatus } from 'redux/types';

export interface CartState {
  items: IProduct[];
  status: ReduxStatus;
  total_product: number;
}

export interface IAdditional {
  id: number;
  name: string;
  qty: number;
  price: number;
  product_id: number;
}

export interface IProduct {
  cart_id?: string;
  product_id: number;
  product_name: string;
  product_image: string;
  qty: number;
  price: number;
  is_customizable: boolean;
  category_id: number;
  category_name: string;
  additionals?: IAdditional[];
}

export interface IChangeQuantity {
  cart_id: string;
  type: 'increase' | 'decrease' | 'remove';
}

export interface UpdatePriceProps {
  product_id: number;
  product_image: string;
  product_name: string;
  price: number;
}

export const initialState: CartState = {
  items: [],
  status: 'loading',
  total_product: 0,
};

export const cartSlice = createSlice({
  name: 'carts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload.product_id) {
            if (!action.payload.is_customizable) {
              let exist = state.items.find(
                (item) => item.product_id === action.payload.product_id
              );
              if (exist) {
                let productIndex = state.items.findIndex(
                  (item) => item.product_id === action.payload.product_id
                );
                state.items[productIndex].qty =
                  action.payload.qty + state.items[productIndex].qty;
              } else {
                state.items.push({
                  ...action.payload,
                  cart_id: generateString(12),
                });
              }
            } else {
              state.items.push({
                ...action.payload,
                cart_id: generateString(12),
              });
            }
          }
          state.total_product = state.items.length;
          state.status = 'success';
        }
      })
      .addCase(addToCart.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(changeQuantity.fulfilled, (state, action) => {
        if (action.payload.cart_id) {
          let exist = state.items.find(
            (x) => x.cart_id === action.payload.cart_id
          );

          if (exist) {
            let idx = state.items.findIndex(
              (x) => x.cart_id === action.payload.cart_id
            );
            if (action.payload.type === 'increase') {
              state.items[idx].qty = state.items[idx].qty + 1;
            } else if (action.payload.type === 'decrease') {
              if (state.items[idx].qty === 1) {
                state.items.splice(idx, 1);
              } else {
                state.items[idx].qty = state.items[idx].qty - 1;
              }
            } else {
              state.items.splice(idx, 1);
            }
          }
        }
      })
      .addCase(changeQuantity.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(changeQuantity.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(updatePrice.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updatePrice.fulfilled, (state, action) => {
        if (action.payload) {
          let exist = state.items.find(
            (item) => item.product_id === action.payload.product_id
          );

          if (exist) {
            let idx = state.items.findIndex(
              (x) => x.product_id === action.payload.product_id
            );

            state.items[idx].price = action.payload.price;
            state.items[idx].product_name = action.payload.product_name;
            state.items[idx].product_image = action.payload.product_image;
          }
        }
      })
      .addCase(updatePrice.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(getTotalProduct.fulfilled, (state, action) => {
        state.total_product = state.items.length;
      })
      .addCase(getTotalProduct.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getTotalProduct.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(resetCart.fulfilled, (state, action) => {
        state.items = [];
        state.total_product = 0;
        state.status = 'loading';
      })
      .addCase(resetCart.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(resetCart.rejected, (state, action) => {
        state.status = 'error';
      });
  },
});

export const updatePrice = createAsyncThunk(
  'carts/updateprice',
  async (props: UpdatePriceProps) => {
    return {
      ...props,
    } as UpdatePriceProps;
  }
);

export const addToCart = createAsyncThunk(
  'carts/addtocart',
  async (props: IProduct) => {
    return {
      ...props,
    } as IProduct;
  }
);

export const getTotalProduct = createAsyncThunk(
  'carts/getTotalProduct',
  async () => {
    return 'success';
  }
);

export const changeQuantity = createAsyncThunk(
  'carts/changequantity',
  async (props: IChangeQuantity) => {
    return {
      ...props,
    } as IChangeQuantity;
  }
);

export const resetCart = createAsyncThunk('carts/reset', async () => {
  return 'Keranjang telah di reset!';
});

export const CartReducer = cartSlice.reducer;
