import { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Wrapper } from './styles';
import { AppContainer } from 'components/styled/layout.styled';
import { MainBackground } from 'assets/images';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'components/styled/toast.css';
import NavigationBar from 'components/Navigation';
import { isAuthenticated, checkExpirySession } from 'helper/general';
import { useNavigate } from 'react-router-dom';
import { persistor } from 'redux/store';

export interface Props {
  title?: string;
  meta?: string;
  description?: string;
  children: ReactNode;
  background?: string;
  padding?: string;
  mainBackground?: any;
  hideBackgroundImage?: boolean;
  hasNavbar?: boolean;
  activePage?: string;
}

const MainLayout = ({
  title,
  children,
  background,
  padding,
  hideBackgroundImage,
  hasNavbar,
  activePage,
}: Props) => {
  const whilelist = [
    '/login',
    '/forgot-password',
    '/404',
    '/password-recovery',
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (!whilelist.some((x) => window.location.pathname.includes(x))) {
      const check = async () => {
        const auth = await isAuthenticated();
        const expirySession = await checkExpirySession();
        if (expirySession) {
          persistor.pause();
          persistor
            .flush()
            .then(() => {
              return persistor.purge();
            })
            .then(() => {
              navigate('/login');
            });
        }

        if (!auth) {
          navigate('/login');
        }
      };

      check().catch(console.error);
    }
  }, [navigate]); //eslint-disable-line
  return (
    <Container>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>{title ?? 'MiniPOS | Pagii Ordering'}</title>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper
        background={background}
        padding={padding}
        mainBackground={hideBackgroundImage ? '' : MainBackground}
      >
        <AppContainer>
          {children}
          {hasNavbar && <NavigationBar activePage={activePage} />}
        </AppContainer>
        <ToastContainer />
      </Wrapper>
    </Container>
  );
};

export const Container = styled.div`
  position: relative;
  display: block;
  background: #f5f5f5;
`;

export default MainLayout;
