import React from 'react';
import { Container, BottomModal } from 'components/BottomSheet';
import styled from 'styled-components';
import { CloseFillIcon, CloseYellowIcon } from 'assets/icons';
import { NormalText } from 'components/styled/text.styled';
import { Box, Stack, Skeleton } from '@mui/material';
import { Button } from 'components/styled/button.styled';
import CounterButton, { CounterProps } from 'components/Counter';
import { formatCurrency } from 'helper/general';
import Checkbox from 'components/Form/Checkbox';
import { getAdditionalList, Addons } from 'redux/product';
import useRedux from 'redux/useRedux';
import { IAdditional } from 'redux/cart/cart.reducers';
import NoPhoto from "assets/images/no-product-photo.svg"


interface IProductDetail {
  productId: number;
  name: string;
  category: string;
  image: string;
  customizable: boolean;
  price: number;
  counterProps: CounterProps;
  onAdd: any;
  onClose: any;
  additionals?: any;
}

const ProductDetail = ({
  category,
  counterProps,
  image,
  name,
  price,
  customizable,
  onClose,
  onAdd,
  productId,
}: IProductDetail) => {
  const {
    thunkDispatch,
    storeState: { Auth, AppOption },
  } = useRedux();
  const [addons, setAddons] = React.useState<Addons[]>();
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<IAdditional[]>([]);

  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  React.useEffect(() => {
    if (customizable && Auth && Auth.status === 'success') {
      setLoading(true);
      thunkDispatch(
        getAdditionalList({
          store_id: AppOption?.selected_store?.id ?? 0,
          product_id: productId,
        }),
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            setAddons(res.data.data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [customizable]); // eslint-disable-line
  return (
    <Container>
      {customizable ? (
        <BottomModal
          height="85vh"
          style={{
            padding: 0,
          }}
        >
          <ImageBox
            sx={{
              background:
                `linear-gradient(rgb(0 0 0 / 50%),rgb(0 0 0 / 50%)), url( 
                ${ imageError ? NoPhoto : image }
                ) no-repeat`,
              backgroundSize: 'cover',
              backgroundPositionY: 'center',
              height: '192px',
              width: '100%',
            }}
            onError={handleImageError}
          >
            <NormalText
              fontWeight="bold"
              fontSize="25px"
              margin="0 0 0 0"
              color={'white'}
            >
              {name}
            </NormalText>
            <NormalText fontSize="18px" color={'white'}>
              {formatCurrency(price)}
            </NormalText>
          </ImageBox>
          <CloseButtonContainer onClick={onClose}>
            <Icon src={CloseYellowIcon} alt={'cloes_ic'} />
          </CloseButtonContainer>

          {loading ? (
            <>
              <Skeleton sx={{ width: '100%' }} />
              <Skeleton sx={{ width: '100%' }} />
              <Skeleton sx={{ width: '100%' }} />
            </>
          ) : addons && addons.find((x) => x.productAddOn.length > 0) ? (
            addons &&
            addons.length > 0 &&
            addons.map(
              (addon, index) =>
                addon.productAddOn.length > 0 && (
                  <div key={'group-' + index}>
                    <LabelHeading>{addon.product_type}</LabelHeading>
                    <Box
                      padding={'0 20px'}
                      sx={{ overflowX: 'auto' }}
                    >
                      {addon.productAddOn.map((item, itemKey) => (
                        <Stack
                          key={'item-' + itemKey}
                          margin={'10px 0 '}
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <Checkbox
                            label={item.product_name}
                            checked={selected.some(
                              (x) => x.id === item.product_assign_id,
                            )}
                            onChange={() => null}
                            onClick={() => {
                              if (
                                selected.some(
                                  (i) => i.id === item.product_assign_id,
                                )
                              ) {
                                let selectedIndex = selected.findIndex(
                                  (i) => i.id === item.product_assign_id,
                                );
                                let temp = [...selected];
                                temp.splice(selectedIndex, 1);
                                setSelected(temp);
                              } else {
                                setSelected([
                                  ...selected,
                                  {
                                    id: item.product_assign_id,
                                    name: item.product_name,
                                    price: Number(item.product_price),
                                    qty: 1,
                                    product_id: item.product_id,
                                  },
                                ]);
                              }
                            }}
                          />
                          <NormalText color={'black'} fontSize={'14px'}>
                            {formatCurrency(Number(item.product_price))}
                          </NormalText>
                        </Stack>
                      ))}
                    </Box>
                  </div>
                ),
            )
          ) : (
            <NormalText
              textAlign="center"
              fontSize={'12px'}
              margin={'20px 0 0 0'}
            >
              Tidak ada produk tambahan.
            </NormalText>
          )}

          <Box padding={'0 15px'} margin={'30px 0 0 0'} position={'relative'}>
            <Box width={'100%'}>
              <StyledStack
                direction={'row'}
                alignItems={'center'}
                margin={'20px 0 0 0'}
                spacing={2}
                sx={{ position: 'fixed', bottom: 20 }}
              >
                <div style={{ width: '60%' }}>
                  <Button
                    variant="primary"
                    width={'100%'}
                    onClick={() => {
                      return onAdd(selected);
                    }}
                  >
                    Add +
                  </Button>
                </div>
                <div style={{ width: '40%' }}>
                  <CounterButton
                    value={counterProps.value}
                    onIncrease={counterProps.onIncrease}
                    onDecrease={counterProps.onDecrease}
                  />
                </div>
              </StyledStack>
            </Box>
          </Box>
        </BottomModal>
      ) : (
        <BottomModal
          height="400px"
          style={{
            padding: 0,
          }}
        >
          {/* validate image for product not customize */}
          {imageError ? (
            <ProductImage
              style={{ borderRadius: '20px 20px 0 0' }}
              src={NoPhoto}
              alt={name}
            />
          ) : (
            <ProductImage
              style={{ borderRadius: '20px 20px 0 0' }}
              src={image}
              alt={name}
              onError={handleImageError}
            />
          )}
          <CloseButtonContainer onClick={onClose}>
            <Icon src={CloseFillIcon} alt={'cloes_ic'} />
          </CloseButtonContainer>
          <Box padding={'0 15px'} margin={'30px 0 0 0'}>
            <NormalText fontSize="13px" margin="0 0 0 0">
              {category}
            </NormalText>
            <NormalText fontWeight="bold" fontSize="25px" margin="0 0 0 0">
              {name}
            </NormalText>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <NormalText fontSize="23px">{formatCurrency(price)}</NormalText>
              <CounterButton
                value={counterProps.value}
                onIncrease={counterProps.onIncrease}
                onDecrease={counterProps.onDecrease}
              />
            </Stack>
            <Button
              variant="primary"
              width={'100%'}
              margin={'20px 0 0 0'}
              onClick={() => {
                return onAdd(selected)
              }}
            >
              Add +
            </Button>
          </Box>
        </BottomModal>
      )}
    </Container>
  );
};

const ImageBox = styled(Box)`
  border-radius: 20px 20px 0 0;
  padding: 80px 10px 10px 10px;
`;

const StyledStack = styled(Stack)`
  width: 380px;
  @media only screen and (min-width: 1024px) {
    width: 430px;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ProductImage = styled.img`
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 192px;
`;

const LabelHeading = styled.div`
  background: #ffee8e;
  height: 32px;
  width: 100%;
  padding: 4px 17px;
  font-family: 'D-DIN';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #333333;
`;

const Icon = styled.img`
  -webkit-user-drag: none;
  user-select: none;
`;

export default ProductDetail;
