import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { Box, Stack } from '@mui/material';
import { AppLogo } from 'assets/images';
import styled from 'styled-components';
import { NormalText } from 'components/styled/text.styled';
import { ContentContainer } from 'components/styled/layout.styled';
import InputField from 'components/Form/InputField';
import { Button } from 'components/styled/button.styled';
import BottomSheet from 'components/BottomSheet';
import { useNavigate, useParams } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { ResetPassword } from 'redux/auth';
import { notify } from 'helper/general';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState({
    new_password: '',
    confirm_password: '',
  });
  const { thunkDispatch } = useRedux();

  const handleReset = () => {
    if (
      id &&
      payload.new_password &&
      payload.confirm_password &&
      payload.new_password.length >= 8 &&
      payload.confirm_password.length >= 8 &&
      payload.new_password === payload.confirm_password
    ) {
      setLoading(true);
      thunkDispatch(
        ResetPassword({
          key: id,
          confirm_password: payload.confirm_password,
          new_password: payload.new_password,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            setSuccess(true);
          } else if (res && res.status === 'error') {
            if (res.error.response) {
              notify('error', res.error.response.data.message);
            }
          }
        })
        .catch((err) => console.log('catch error', err.mesage))
        .finally(() => setLoading(false));
    }
  };

  return (
    <MainLayout background="#FFE39D">
      <Box width={'100%'}>
        <StyledBox>
          <StyledContainter>
            <img src={AppLogo} alt={'App Logo'} />
          </StyledContainter>
        </StyledBox>
        <ContentContainer>
          <NormalText
            textAlign={'center'}
            fontSize={'20px'}
            fontWeight={'bold'}
            lineHeight={'24px'}
            margin={'30px 0 0 0'}
          >
            Password Baru
          </NormalText>
          <NormalText
            textAlign={'center'}
            fontSize={'13px'}
            lineHeight={'24px'}
            margin={'0 0 40px 0'}
          >
            Masukan password baru
          </NormalText>
          <Stack direction={'column'} spacing={2}>
            <InputField
              inputIcon={'LockIcon'}
              label={'Password Baru'}
              required
              type={'password'}
              onChange={(e: any) =>
                setPayload({ ...payload, new_password: e.target.value })
              }
              placeholder={'Masukan Password Baru Anda'}
              validationType={
                payload.new_password && payload.new_password.length < 8
                  ? 'error'
                  : undefined
              }
              validationText={'Password wajib diisi dan minimum 8 karakter'}
            />
            <InputField
              inputIcon={'LockIcon'}
              label="Verifikasi Password Baru"
              required
              onChange={(e: any) =>
                setPayload({ ...payload, confirm_password: e.target.value })
              }
              type={'password'}
              placeholder="Verifikasi Password Baru Anda"
              validationType={
                payload.confirm_password &&
                payload.confirm_password !== payload.new_password
                  ? 'error'
                  : undefined
              }
              validationText={'Password verifikasi tidak sama, mohon ulangi'}
            />
          </Stack>
          <Button
            width={'100%'}
            variant={loading ? 'secondary' : 'primary'}
            margin="60px 0 0 0"
            onClick={() => (loading ? null : handleReset())}
          >
            {loading ? 'Tunggu Sebentar' : 'Selanjutnya'}
          </Button>
          <NormalText
            fontSize="14px"
            textAlign={'center'}
            letterSpacing={'0.1px'}
            margin={'70px 0 0 0'}
          >
            2023 © All Reserved by Sandbox
          </NormalText>
        </ContentContainer>
        {success && <InformationSheet onConfirm={() => navigate('/login')} />}
      </Box>
    </MainLayout>
  );
};

const InformationSheet = ({ onConfirm }: { onConfirm: any }) => {
  return (
    <BottomSheet height="185px">
      <Box padding={'40px'}>
        <NormalText fontSize="16px" margin="0 0 25px 0">
          Password anda telah di perbaharui
        </NormalText>
        <Button variant="primary" onClick={onConfirm}>
          OK
        </Button>
      </Box>
    </BottomSheet>
  );
};

const StyledBox = styled(Box)`
  height: 250px;
  position: relative;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default ResetPasswordPage;
