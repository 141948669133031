import { createAsyncThunk } from "@reduxjs/toolkit";
import { MemberState } from "./types";
import { getToken } from "helper/general";
import axios from "axios";

interface MemberPayload {
    full_name: string;
    phone_number: string;
}

export const checkMember = createAsyncThunk('member/check/create', async ({ full_name, phone_number }: MemberPayload) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
            token: getToken()
        },
    };
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/member/loyalty/check`, { full_name, phone_number },
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null,
            } as unknown as MemberState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null,
            } as unknown as MemberState;
        }
    } catch (err) {
        if (err)
            return {
                data: "Something went wrong!",
                status: "error",
                error: err,
            } as unknown as MemberState;
    }
})