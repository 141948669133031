import axios from 'axios'

export interface IFeeSetting {
    id: number,
    date: string,
    tax: number,
    service: number,
    platform_fee_type: string,
    payment_fee_type: "CUSTOMER" | "OWNER" | "SPLIT",
    updated_by: string
}

export const getFeeSetting = async (merchantId: number) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };

    let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/feesetting/${merchantId}`, config);
    if (response && response.data && response.data.data) {
        return response.data.data as IFeeSetting;
    } else {
        return {} as IFeeSetting;
    }
}

export const getPlatformfee = async (total: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/feesettingV2/${total}`,
        {
          method: "GET",
          headers: {
            api_key:  process.env.REACT_APP_API_KEY ?? '',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };